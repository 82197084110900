import React, { Fragment, useRef, useState, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import ReplayIcon from "@mui/icons-material/Replay";
import { IconButton } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";

export const DigitalSignature = ({
  firma,
  setFirma,
  setFirmar,
  setFullScreen,
  ...props
}) => {
  const signatureRef = useRef({});
  const canvasRef = useRef(null);
  const [error, setError] = useState(false);
  const saveSignature = (signature) => {
    setFirma(signature);
  };

  const setFullScreenMode = (refe) => {
    refe
      .requestFullscreen()
      .then(function () {
        // element has entered fullscreen mode successfully
      })
      .catch(function (error) {
        // element could not enter fullscreen mode
        // error message
        console.log(error.message);
      });
  };

  useEffect(() => {
    setFullScreenMode(canvasRef.current);
  }, [canvasRef]);

  return (
    <div ref={canvasRef} style={{ backgroundColor: "white" }}>
      <SignatureCanvas
        canvasProps={{
          style: {
            background: "transparent",
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            height: "-webkit-fill-available",
            width: "-webkit-fill-available",
            zIndex: "10000",
          },
        }}
        penColor="blue"
        ref={signatureRef}
        onEnd={() => saveSignature(signatureRef.current.getTrimmedCanvas())}
        onBegin={() => {
          setError(false);
        }}
      />
      <LoadingButton
        variant="contained"
        onClick={() => setFirmar(false)}
        size="medium"
        startIcon={<SaveIcon />}
        style={{
          color: "#fff",
          position: "fixed",
          bottom: "0",
          left: "0",
          right: "0",
          zIndex: "10001",
        }}>
        Completado
      </LoadingButton>

      <IconButton
        sx={{ height: "40px" }}
        onClick={() => {
          signatureRef.current.clear();
          saveSignature(null);
        }}>
        <ReplayIcon />
      </IconButton>
      <pre>{error ? <div>La firma es obligatoria</div> : false}</pre>
    </div>
  );
};
