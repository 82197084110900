// COMPONENTE REUTILIZABLE DE INPUT TIPO SELECT
import { MenuItem, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { SelectStyles } from "../utils/styles";

let VS = SelectStyles();

const SelectFormat = ({
  placeholder,
  datos,
  value,
  estilo,
  onChange = () => {},
  ...props
}) => {
  let SelectStyles = VS();
  return (
    <Box
      sx={{
        backgroundColor: estilo,
        height: "41px",
        border: "2px solid #C7C7C7",
      }}
      className={SelectStyles.box}>
      <TextField
        label={placeholder}
        select
        value={value}
        onChange={onChange}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
        className={SelectStyles.boxItem}>
        {datos.map((dato, i) => (
          <MenuItem
            key={i}
            value={dato}
            sx={{
              color: "#949494",
              fontSize: "14px",
              fontWeight: "medium",
              lineHeight: "10px",
              height: "35px",
              fontFamily: "poppins",
            }}>
            {dato}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

export default SelectFormat;
