import React, { useState } from "react";
import { Grid, IconButton } from "@mui/material";
import { ChecklistStyles } from "../../utils/styles";
import Input from "../../components/Input";
import AutocompleteDeluxe from "../../components/AutocompleteDelux";
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { useModal } from "../../hooks/useModal";
import { LoadingButton } from "@mui/lab";
import Checklists from "../checklist";
import { NavLink } from "react-router-dom";
import { productDetails } from "../../utils/styles";
import { useLocalStorage } from "../../hooks/useLocalStorage";

let vs = ChecklistStyles();
const DatosOrdenServicio = ({
  formDetails,
  setformDetails,
  empresa,
  setEmpresa,
  checklist,
  setChecklist,
  guardarDatosOrdenServicio,
}) => {
  let ChecklistStyles = vs();
  const { setContainerModal, setOpen } = useModal();
  const [modalData, setModalData] = useState({ data: [] });
  const permisoCheck = "ordenServicios";
  const [permiso] = useLocalStorage("permiso", null);

  const handleVisibilityClick = (dataList) => {
    setModalData({ data: dataList });

    const modalContent = (
      <div style={{ textAlign: "center" }}>
        <>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr style={{ backgroundColor: "#157CC1", color: "#FFFFFF" }}>
                <th style={{ padding: "10px" }}>Fecha</th>
                <th style={{ padding: "10px" }}>Placas</th>
                <th style={{ padding: "10px" }}>Económico</th>
                <th style={{ padding: "10px" }}>Empresa</th>
                <th style={{ padding: "10px" }}>Proyecto</th>
              </tr>
            </thead>
            <tbody>
              {dataList.map((e, i) => (
                <tr key={i} style={{
                  backgroundColor: i % 2 === 0 ? "#FFFFFF" : "#D9D9D9",
                }} onClick={() => {
                  setChecklist({
                    idChecklist: e.idChecklist,
                    economico: e.datoschecklists?.carros?.economico,
                    placas: e.datoschecklists?.carros?.placas,
                    modelo: e.datoschecklists?.carros?.modelo,
                    marca: e.datoschecklists?.carros?.marca,
                    year: e.datoschecklists?.carros?.year,
                    vin: e.datoschecklists?.carros?.vin,
                    fechaPoliza: e.datoschecklists?.carros?.fechaPoliza,
                    tarjetaCirculacion: e.datoschecklists?.carros?.tarjetaCirculacion,
                    nombreEmpresa: e.datoschecklists?.empresas?.nombre,
                    proyecto: e.datoschecklists?.areas?.nombre,
                    notas: e.gasolinachecklists?.notas,
                    kilometraje: e.gasolinachecklists?.kilometraje,
                    gasolina: e.gasolinachecklists?.gasolina,
                  });
                  setOpen(false);
                }}>
                  <td style={{ padding: "10px", textAlign: "center" }}>{e.creado}</td>
                  <td style={{ padding: "10px", textAlign: "center" }}>{e.datoschecklists?.carros?.placas}</td>
                  <td style={{ padding: "10px", textAlign: "center" }}>{e.datoschecklists?.carros?.economico}</td>
                  <td style={{ padding: "10px", textAlign: "center" }}>{e.datoschecklists?.empresas?.nombre}</td>
                  <td style={{ padding: "10px", textAlign: "center" }}>{e.datoschecklists?.areas?.nombre}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      </div>
    );
    if (setContainerModal) {
      setContainerModal(modalContent);
      setOpen(true);
    } else {
      console.error("no existe información para mostrar");
    }
  };

  return (
    <div className={ChecklistStyles.componentContainer}>
      <div className={ChecklistStyles.internContainer}>
        {/* <Grid item xs={12} sm={2} md={2}>
          <div>Checklists seleccionado: {formDetails?.idChecklist}</div>
        </Grid> */}
        <Grid container direction="row" columns={{ xs: 12, sm: 12, md: 12 }}>
          <div className={productDetails.top}>
            {(permiso[permisoCheck] & 1) === 1 ? (
              <NavLink exact to={"/ordenServicios"}>
                « Volver
              </NavLink>
            ) : (
              <p>« Volver</p>
            )}
          </div>
          <Grid item xs={12} sm={2} md={2}
            className={ChecklistStyles.inputArea}
            style={{ display: 'flex', alignItems: 'flex-end' }}>
            <IconButton variant="contained" onClick={() => handleVisibilityClick(checklist || [])}>
              <FactCheckIcon />
            </IconButton>

          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            className={ChecklistStyles.inputArea}>
            <AutocompleteDeluxe
              labelToShow="Empresa que pagará"
              labelProp="nombre"
              nombre="empresas"
              value={empresa}
              setDato={setEmpresa}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            md={2}
            sx={{
              "@media (min-width: 600px)": {
                paddingRight: "10px",
              },
            }}
            className={ChecklistStyles.inputArea}
          >
            <Input
              disabled={true}
              typeInput={"date"}
              nombre="Fecha de Solicitud"
              valor={formDetails?.fechaSolicitud}
              setValor={(setFormDetails) => {
                setFormDetails({
                  ...formDetails,
                  fechaSolicitud: formDetails,
                });
              }}
            />
          </Grid>
          <Grid item
            xs={12}
            sm={4}
            md={4}
            sx={{
              "@media (min-width: 600px)": {
                paddingRight: "10px",
              },
            }}
            className={ChecklistStyles.inputArea}>
            <Input
              disabled={true}
              typeInput={"form"}
              typeFormat="text"
              valor={checklist.economico}
              setValor={setChecklist}
              placeholder="No. Economico"
              nombre="No. Economico"
              required="false"
              onChange={(e) => {
                setChecklist({
                  ...checklist,
                  economico: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item
            xs={12}
            sm={4}
            md={4}
            sx={{
              "@media (min-width: 600px)": {
                paddingRight: "10px",
              },
            }}
            className={ChecklistStyles.inputArea}>
            <Input
              typeInput={"form"}
              valor={checklist.vin}
              setValor={setChecklist}
              placeholder="VIN"
              nombre="Número de chasis"
              disabled={true}
              required="false"
              onChange={(e) => {
                setChecklist({
                  ...checklist,
                  vin: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item
            xs={12}
            sm={4}
            md={4}
            sx={{
              "@media (min-width: 600px)": {
                paddingRight: "10px",
              },
            }}
            className={ChecklistStyles.inputArea}>
            <Input
              typeInput={"form"}
              valor={checklist.placas}
              setValor={setChecklist}
              placeholder="Placas"
              nombre="Placas"
              disabled={true}
              required="false"
              onChange={(e) => {
                setChecklist({
                  ...checklist,
                  placas: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item
            xs={12}
            sm={4}
            md={4}
            sx={{
              "@media (min-width: 600px)": {
                paddingRight: "10px",
              },
            }}
            className={ChecklistStyles.inputArea}>
            <Input
              typeInput={"form"}
              valor={checklist.fechaPoliza}
              setValor={setChecklist}
              placeholder="Fecha de Poliza"
              nombre="Fecha de Poliza"
              disabled={true}
              required="false"
              onChange={(e) => {
                setChecklist({
                  ...checklist,
                  fechaPoliza: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item
            xs={12}
            sm={4}
            md={4}
            sx={{
              "@media (min-width: 600px)": {
                paddingRight: "10px",
              },
            }}
            className={ChecklistStyles.inputArea}>
            <Input
              typeInput={"form"}
              valor={checklist.tarjetaCirculacion}
              setValor={setChecklist}
              placeholder="Tarjeta de Circulación"
              nombre="Tarjeta de Circulación"
              disabled={true}
              required="false"
              onChange={(e) => {
                setChecklist({
                  ...checklist,
                  tarjetaCirculacion: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item
            xs={12}
            sm={4}
            md={4}
            sx={{
              "@media (min-width: 600px)": {
                paddingRight: "10px",
              },
            }}
            className={ChecklistStyles.inputArea}>
            <Input
              typeInput={"form"}
              valor={checklist.modelo}
              setValor={setChecklist}
              placeholder="Modelo"
              nombre="Modelo"
              disabled={true}
              required="false"
              onChange={(e) => {
                setChecklist({
                  ...checklist,
                  modelo: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item
            xs={12}
            sm={4}
            md={4}
            sx={{
              "@media (min-width: 600px)": {
                paddingRight: "10px",
              },
            }}
            className={ChecklistStyles.inputArea}>
            <Input
              typeInput={"form"}
              valor={checklist.marca}
              setValor={setChecklist}
              placeholder="Marca"
              nombre="Marca"
              disabled={true}
              required="false"
              onChange={(e) => {
                setChecklist({
                  ...checklist,
                  marca: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item
            xs={12}
            sm={4}
            md={4}
            sx={{
              "@media (min-width: 600px)": {
                paddingRight: "10px",
              },
            }}
            className={ChecklistStyles.inputArea}>
            <Input
              typeInput={"form"}
              valor={checklist.year}
              setValor={setChecklist}
              placeholder="Año"
              nombre="Año"
              disabled={true}
              required="false"
              onChange={(e) => {
                setChecklist({
                  ...checklist,
                  year: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item
            xs={12}
            sm={4}
            md={4}
            sx={{
              "@media (min-width: 600px)": {
                paddingRight: "10px",
              },
            }}
            className={ChecklistStyles.inputArea}>
            <Input
              typeInput={"form"}
              valor={checklist.kilometraje}
              setValor={setChecklist}
              placeholder="kilometraje"
              nombre="Kiloemtraje"
              disabled={true}
              required="false"
              onChange={(e) => {
                setChecklist({
                  ...checklist,
                  kilometraje: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item
            xs={12}
            sm={4}
            md={4}
            sx={{
              "@media (min-width: 600px)": {
                paddingRight: "10px",
              },
            }}
            className={ChecklistStyles.inputArea}>
            <Input
              typeInput={"form"}
              valor={checklist.gasolina}
              setValor={setChecklist}
              placeholder="Gasolina"
              nombre="% Gasolina"
              disabled={true}
              required="false"
              onChange={(e) => {
                setChecklist({
                  ...checklist,
                  gasolina: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item
            xs={12}
            sm={4}
            md={4}
            sx={{
              "@media (min-width: 600px)": {
                paddingRight: "10px",
              },
            }}
            className={ChecklistStyles.inputArea}>
            <Input
              typeInput={"form"}
              valor={checklist.nombreEmpresa}
              setValor={setChecklist}
              placeholder="Nombre"
              nombre="Empresa dueña del vehiculo"
              disabled={true}
              required="false"
              onChange={(e) => {
                setChecklist({
                  ...checklist,
                  nombreEmpresa: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item
            xs={12}
            sm={4}
            md={4}
            sx={{
              "@media (min-width: 600px)": {
                paddingRight: "10px",
              },
            }}
            className={ChecklistStyles.inputArea}>
            <Input
              typeInput={"form"}
              valor={checklist.proyecto}
              setValor={setChecklist}
              placeholder="Nombre"
              nombre="Proyecto"
              disabled={true}
              required="false"
              onChange={(e) => {
                setChecklist({
                  ...checklist,
                  proyecto: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item
            xs={12}
            sm={12}
            md={12}
            sx={{
              "@media (min-width: 600px)": {
                paddingRight: "10px",
              },
            }}
            style={{ display: "flex", justifyContent: "center", width: "100%", height: "177px" }}
            className={ChecklistStyles.inputArea}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "center",
              }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "17px",
                  width: "95%",
                  backgroundColor: "#157CC1",
                  borderTopRightRadius: "5px",
                  borderTopLeftRadius: "5px",
                  border: "1px solid black",
                }}>
                <Grid
                  style={{
                    textAlign: "center",
                    height: "90%",
                    fontSize: "12px",
                    color: "black",
                  }}>
                  NOTAS DE INSPECCIÓN
                </Grid>
              </div>
              {checklist?.notas?.map((item, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      display: "flex",
                      width: "95%",
                      borderBottom: "1px solid black",
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                    }}>
                    <Grid
                      style={{
                        paddingLeft: "10px",
                        fontSize: "12px",
                        color: "black",
                      }}>
                      {i + 1}-. {item.nota}
                    </Grid>
                  </div>
                );
              })}
            </div>
          </Grid>

        </Grid>
        <div className={ChecklistStyles?.buttonAreaContainer}>
          <div className={ChecklistStyles?.buttonContainer}
            style={{ display: "flex", justifyContent: "flex-end" }}>
            <LoadingButton
              // loading={loadingB}
              // onClick={guardar}
              sx={{
                width: "170px",
                height: "50px",
                backgroundColor: "#157CC1",
                fontFamily: "poppins",
                borderRadius: "10px",
                border: "none",
                fontWeight: "500",
                fontSize: "14px",
                letterSpacing: "0.07em",
                color: "#FFFFFF",
                marginLeft: "2px",
                "@media (min-width: 600px)": {
                  marginBottom: "6px",
                },
                "&:hover": {
                  backgroundColor: "#1565c0",
                  cursor: "pointer",
                },
              }}
              className={ChecklistStyles?.buttonSave}
              onClick={guardarDatosOrdenServicio}>
              GUARDAR
            </LoadingButton>
          </div>
        </div>
      </div>


    </div>
  );
};
export default DatosOrdenServicio;
