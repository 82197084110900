import React, { useState } from "react";
import Button from "../components/Button";
import Input from "../components/Input";
import InputMessage from "../components/InputMessage";
import { FaEnvelope } from "react-icons/fa";
import Avatar from "../components/Avatar";
import { HiEye, HiEyeOff } from "react-icons/hi";
const Login = ({
  onClick,
  correo,
  setCorreo,
  clave,
  setClave,
  errorFirebase,
  obtenerErrorFirebase,
  ...props
}) => {
  const [usuario, setUsuario] = useState(
    "https://firebasestorage.googleapis.com/v0/b/zonda-b0ce9.appspot.com/o/Sistema%2FUser-169.png?alt=media&token=7e3f9219-d186-477c-82d7-13de8810fa88"
  );
  return (
    <div>
      <Avatar img={usuario} alt={"imagen del usuario default"} size={"169px"} />
      <Input
        typeInput={"mail"}
        style={{ margin: "21px 0 0 0" }}
        valor={correo.toUpperCase()}
        setValor={setCorreo}
        placeholder="Correo Electronico"
        Icon={FaEnvelope}
        onKeyDown={(e) => {
          if (e.key == "Enter") {
            onClick(e);
          }
        }}
        IconStyle={{ width: "20px", height: "17px", color: "#157CC1" }}
      />
      <Input
        typeInput={"password"}
        style={{ margin: "21px 0 0 0" }}
        valor={clave}
        setValor={setClave}
        placeholder="Contraseña"
        onKeyDown={(e) => {
          if (e.key == "Enter") {
            onClick(e);
          }
        }}
        IconStyle={{
          width: "20px",
          height: "17px",
          color: "#157CC1",
        }}
      />
      <Button
        style={{ width: "100%", margin: "21px 0" }}
        primary
        onClick={onClick}
        label="ENTRAR"
      />
      {errorFirebase !== null && (
        <InputMessage message={obtenerErrorFirebase(errorFirebase?.message)} />
      )}
    </div>
  );
};

export default Login;
