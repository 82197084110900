import React, { useState } from "react";
import { Grid, Hidden } from "@mui/material";
import { obtenerErrorFirebase } from "../const/erroresFirebase";
import { useAuth } from "../hooks/useAuth";
import Login from "../containers/Login";
import Vista from "../components/Vista";

export default function SignIn() {
  const { signIn, errorFirebase } = useAuth();
  const [correo, setCorreo] = useState("");
  const [clave, setClave] = useState("");
  const logo =
    "https://firebasestorage.googleapis.com/v0/b/zonda-b0ce9.appspot.com/o/Sistema%2FGroup%207.png?alt=media&token=64a13f0b-4242-42fb-9b9e-691b19ef3ae8";

  const onSubmitClicked = async () => {
    try {
      window.localStorage.clear();
      await signIn(correo, clave);
    } catch (error) {}
  };

  return (
    <Vista
      bg
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "flex-start",
            position: "fixed",
            top: 60,
            right: 30,
          }}
        >
          <Hidden mdDown>
            <img style={{ width: "192px" }} src={logo} alt={logo} />
          </Hidden>
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Grid item xs={1} sm={3} md={4} lg={4}></Grid>
        <Grid item xs={10} sm={6} md={4} lg={3}>
          <Login
            onClick={onSubmitClicked}
            correo={correo}
            clave={clave}
            setCorreo={setCorreo}
            setClave={setClave}
            errorFirebase={errorFirebase}
            obtenerErrorFirebase={obtenerErrorFirebase}
          />
        </Grid>
        <Grid item xs={1} sm={3} md={4} lg={4}></Grid>
      </Grid>
    </Vista>
  );
}
