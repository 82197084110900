import React, { useState } from "react";
import { InputStyles } from "../utils/styles";
import { HiEye, HiEyeOff } from "react-icons/hi";
import SelectFormat from "./SelectFormat";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";

let IS = InputStyles();
const Input = ({
  disabled = false,
  Icon,
  Icon2,
  IconStyle,
  placeholder,
  valor,
  setValor,
  onChange = () => { },
  style,
  typeInput,
  typeFormat = "text",
  onKeyDown,
  nombre = "",
  required = "false",
  data = [],
  nombreBusqueda = "",
  ...props
}) => {
  let InputStyles = IS();
  const [showPsw, setShowPsw] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);

  const validations = {
    number: (e, fun) => {
      const regex = /^\d*$/;
      if (e.target.value.match(regex)) {
        fun(e)
      }
    },
    numberDecimal: (e, fun) => {
      const regex = /^((?!0)\d{1,10}|0|\.\d{1,2})($|\.$|\.\d{1,2}$)/;
      if (e.target.value.match(regex) || e.target.value == "") {
        fun(e);
      }
    },
    text: (e, fun) => {
      fun(e);
    },
    text100: (e, fun) => {
      if (e.target.value.length <= 100) {
        fun(e);
      }
    },
    ine: (e, fun) => {
      const regex = /^\d*$/;
      if (e.target.value.match(regex)) {
        if (e.target.value.length <= 13) fun(e);
      }
    },
    phone: (e, fun) => {
      const regex = /^\d*$/;
      if (e.target.value.match(regex)) {
        if (e.target.value.length <= 10) fun(e);
      }
    },
    percent: (e, fun) => {
      const regex = /^\d*$/;
      if (e.target.value.match(regex)) {
        if (e.target.value > 100) {
          e.target.value = 100;
          fun(e);
        } else {
          fun(e);
        }
      }
    },
    llantas: (e, fun) => {
      const regex = /^\d*$/;
      if (e.target.value.length <= 2) {
        if (e.target.value.match(regex)) {
          if (e.target.value > 10) {
            e.target.value = 10;
            fun(e);
          } else {
            fun(e);
          }
        }
      }
    },
    frenos: (e, fun) => {
      const regex = /^\d*$/;
      if (e.target.value.length <= 2) {
        if (e.target.value.match(regex)) {
          if (e.target.value > 12) {
            e.target.value = 12;
            fun(e);
          } else {
            fun(e);
          }
        }
      }
    },
    gasolina: (e, fun) => {
      const regex = /^\d*$/;
      if (e.target.value.length <= 3) {
        if (e.target.value.match(regex)) {
          if (e.target.value > 100) {
            e.target.value = 100;
          } else {
            fun(e);
          }
        }
      }
    },
  };

  switch (typeInput) {
    case "mail":
      return (
        <div style={style} className={InputStyles.input}>
          <input
            placeholder={placeholder}
            value={valor}
            onChange={(e) => {
              setValor(e.target.value);
            }}
            onKeyDown={onKeyDown}
          />
          <div>
            <Icon className={InputStyles.icon} style={IconStyle} />
          </div>
        </div>
      );
      break;

    case "password":
      return (
        <div style={style} className={InputStyles.input}>
          <input
            placeholder={placeholder}
            value={valor}
            type={showPsw ? "text" : "password"}
            onChange={(e) => {
              setValor(e.target.value);
            }}
            onKeyDown={onKeyDown}
          />
          <div>
            {showPsw ? (
              <HiEyeOff
                onClick={(e) => setShowPsw(false)}
                className={InputStyles.icon}
                style={{ ...IconStyle, cursor: "pointer" }}
              />
            ) : (
              <HiEye
                onClick={(e) => setShowPsw(true)}
                className={InputStyles.icon}
                style={{ ...IconStyle, cursor: "pointer" }}
              />
            )}
          </div>
        </div>
      );
      break;

    case "search":
      return (
        <div className={InputStyles.inputSearch}>
          <div style={{ height: "41px" }}>
            <input
              placeholder={placeholder + " " + nombreBusqueda}
              value={valor}
              onChange={onChange}
              onKeyDown={onKeyDown}
            />
          </div>
          <div>
            <Icon className={InputStyles.icon} style={IconStyle} />
          </div>
        </div>
      );
      break;

    case "onlyInput":
      return (
        <div className={InputStyles.inputContent}>
          <input
            style={style}
            placeholder={placeholder}
            className={InputStyles.onlyInput}
            value={valor}
            onChange={(e) => {
              validations[typeFormat](e, onChange);
            }}
            onKeyDown={onKeyDown}
          />
        </div>
      );

    case "formPassword":
      return (
        <div className={InputStyles.formContent}>
          {required === "true" ? (
            <div className={InputStyles.text}>
              <p>{nombre}</p>
              <p className={InputStyles.required}>*</p>
            </div>
          ) : (
            <div className={InputStyles.text}>
              <p>{nombre}</p>
            </div>
          )}
          <div className={InputStyles.inputSize}>
            <div style={{ width: "85%", height: "100%" }}>
              <input
                className={InputStyles.inputFormIcon}
                placeholder={placeholder}
                value={valor}
                type={showPsw ? "text" : "password"}
                onChange={(e) => {
                  validations[typeFormat](e, onChange);
                }}
                onKeyDown={onKeyDown}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "15%",
                height: "100%",
              }}>
              {showPsw ? (
                <Icon
                  className={InputStyles.iconForm}
                  style={IconStyle}
                  onClick={(e) => setShowPsw(false)}
                />
              ) : (
                <Icon2
                  className={InputStyles.iconForm}
                  style={IconStyle}
                  onClick={(e) => setShowPsw(true)}
                />
              )}
            </div>
          </div>
        </div>
      );

    case "form":
      return (
        <div className={InputStyles.formContent}>
          <div className={InputStyles.text}>
            <p>{nombre}</p>
            {required === "true" && <p className={InputStyles.required}>*</p>}
          </div>
          <div className={InputStyles.formInput}>
            {disabled === true ? (
              <input
                disabled={true}
                className={InputStyles.inputFormularys}
                placeholder={placeholder}
                value={valor}
                onChange={(e) => {
                  validations[typeFormat](e, onChange);
                }}
                onKeyDown={onKeyDown}
              />
            ) : (
              <input
                className={InputStyles.inputFormularys}
                placeholder={placeholder}
                value={valor}
                onChange={(e) => {
                  validations[typeFormat](e, onChange);
                }}
                onKeyDown={onKeyDown}
              />
            )}
          </div>
        </div>
      );

    case "formIcon":
      return (
        <div className={InputStyles.formContent}>
          {required === "true" ? (
            <div className={InputStyles.text}>
              <p>{nombre}</p>
              <p className={InputStyles.required}>*</p>
            </div>
          ) : (
            <div className={InputStyles.text}>
              <p>{nombre}</p>
            </div>
          )}
          <div className={InputStyles.formInput}>
            <div style={{ width: "85%", height: "100%" }}>
              {disabled === true ? (
                <input
                  disabled={true}
                  className={InputStyles.inputFormIcon}
                  placeholder={placeholder}
                  value={valor}
                  onChange={(e) => {
                    validations[typeFormat](e, onChange);
                  }}
                  onKeyDown={onKeyDown}
                />
              ) : (
                <input
                  className={InputStyles.inputFormIcon}
                  placeholder={placeholder}
                  value={valor}
                  onChange={(e) => {
                    validations[typeFormat](e, onChange);
                  }}
                  onKeyDown={onKeyDown}
                />
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "15%",
                height: "100%",
              }}>
              <Icon className={InputStyles.iconForm} style={IconStyle} />
            </div>
          </div>
        </div>
      );

    case "formSelect":
      return (
        <div className={InputStyles.formContent}>
          {required === "true" ? (
            <div className={InputStyles.text}>
              <p>{nombre}</p>
              <p className={InputStyles.required}>*</p>
            </div>
          ) : (
            <div className={InputStyles.text}>
              <p>{nombre}</p>
            </div>
          )}
          <div>
            <SelectFormat
              estilo={style}
              value={valor}
              onChange={onChange}
              datos={data}
            />
          </div>
        </div>
      );

    case "date":

      return (
        <div className={InputStyles.formContent}>
          {required === "true" ? (
            <div className={InputStyles.text}>
              <p>{nombre}</p>
              <p className={InputStyles.required}>*</p>
            </div>
          ) : (
            <div className={InputStyles.text}>
              <p>{nombre}</p>
            </div>
          )}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {disabled === true ? (
              <DatePicker
                className={InputStyles.dateDesing}
                label={required === "true" ? `${nombre} *` : nombre}
                value={valor}
                disabled={true}
                open={calendarOpen}
                onOpen={() => setCalendarOpen(true)}
                onClose={() => setCalendarOpen(false)}
                onChange={(newValue) => {
                  setValor(newValue);
                  setCalendarOpen(false); // Cerrar el calendario después de seleccionar la fecha
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      style: { border: 'none', width: '100%', height: '100%' },
                      onClick: () => setCalendarOpen(true), // Abrir el calendario al hacer clic en el campo
                    }}
                    InputLabelProps={{
                      style: { color: 'grey' },
                    }}
                    className={InputStyles.inputFormularys}
                  />
                )}
              />
            ) : (
              <DatePicker
                className={InputStyles.dateDesing}
                label={required === "true" ? `${nombre} *` : nombre}
                value={valor}
                open={calendarOpen}
                onOpen={() => setCalendarOpen(true)}
                onClose={() => setCalendarOpen(false)}
                onChange={(newValue) => {
                  setValor(newValue);
                  setCalendarOpen(false); // Cerrar el calendario después de seleccionar la fecha
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      style: { border: 'none', width: '100%', height: '100%' },
                      onClick: () => setCalendarOpen(true), // Abrir el calendario al hacer clic en el campo
                    }}
                    InputLabelProps={{
                      style: { color: 'grey' },
                    }}
                    className={InputStyles.inputFormularys}
                  />
                )}
              />
            )

            }
          </LocalizationProvider>
        </div>
      );

    default:
  }
};

export default Input;
