import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useState } from "react";
import Input from "../../components/Input";
import Vista from "../../components/Vista";
import { productDetails } from "../../utils/styles";
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { LoadingButton } from "@mui/lab";

let vs = productDetails();
const Detail = ({
  observaciones,
  setObservaciones,
  guardarObservacion,
}) => {
  const [observacion, setObservacion] = useState("");

  const addObservacion = () => {
    if (observacion.trim() !== "") {
      setObservaciones([...observaciones, { id: observaciones.length + 1, observacion: observacion }]);
    }
    setObservacion("");
  };

  const deleteObservacion = (index) => {
    const newObservaciones = [...observaciones];
    newObservaciones.splice(index, 1);
    setObservaciones(newObservaciones);
  };

  let productDetails = vs();

  return (
    <Vista>
      <div className={productDetails.screenSize}>
        <div className={productDetails.formArea}>
          <Grid container direction="row" columns={{ xs: 12, sm: 12, md: 12 }}>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              sx={{
                "@media (min-width: 600px)": {
                  paddingRight: "10px",
                  marginBottom: "10px",
                },
                display: "flex", alignItems: "flex-end"
              }}
              className={productDetails.inputArea}
            >
              <Input
                typeInput={"form"}
                valor={observacion}
                setValor={setObservacion}
                placeholder="Observacion"
                nombre="Observacion"
                required="false"
                onChange={(e) => {
                  setObservacion(e.target.value);
                }}
              />
              <IconButton onClick={addObservacion} color="primary">
                <AddCircleIcon />
              </IconButton>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              sx={{
                "@media (min-width: 600px)": {
                  paddingRight: "10px",
                },
              }}
              className={productDetails.inputArea}
            >
            </Grid>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Observacion</TableCell>
                    <TableCell>Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {observaciones && observaciones.length > 0 && observaciones.map((obs, index) => (
                    <TableRow key={index}>
                      <TableCell>{obs.observacion}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => deleteObservacion(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <div className={productDetails?.buttonAreaContainer}>
            <div className={productDetails?.buttonContainer}
              style={{ display: "flex", justifyContent: "flex-end", paddingTop: "10px" }}>
              <LoadingButton
                // loading={loadingB}
                // onClick={guardar}
                sx={{
                  width: "170px",
                  height: "50px",
                  backgroundColor: "#157CC1",
                  fontFamily: "poppins",
                  borderRadius: "10px",
                  border: "none",
                  fontWeight: "500",
                  fontSize: "14px",
                  letterSpacing: "0.07em",
                  color: "#FFFFFF",
                  marginLeft: "2px",
                  "@media (min-width: 600px)": {
                    marginBottom: "6px",
                  },
                  "&:hover": {
                    backgroundColor: "#1565c0",
                    cursor: "pointer",
                  },
                }}
                className={productDetails?.buttonSave}
                onClick={async (e) => {
                  {
                    guardarObservacion();
                  }
                }}>
                GUARDAR
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
    </Vista>
  );
};

export default Detail;