import React, { useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Input from "../../components/Input";
import { EstilosButton, OrdenCompraStyles } from "../../utils/styles";
import { useAlert } from "../../hooks/useAlert";
import Vista from "../../components/Vista";
import { BiPlus } from "react-icons/bi";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { LoadingButton } from "@mui/lab";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useMessages } from "../../hooks/useMessages";
import AutocompleteDeluxe from "../../components/AutocompleteDelux";

const VS = OrdenCompraStyles();
const VS2 = EstilosButton();

const defaultPropiedades = {
  operacion: "",
  comentario: "",
};

const Diagnosticos = ({
  guardarRefaccionesOrdenServicio,
  finalizarOrdenServicio,
  productos,
  setProductos,
}) => {
  const OrdenCompraStyles = VS();
  const EstilosButton = VS2();
  const operacion = "Salida"; // La operación siempre será "Salida"
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState("");
  const { showAlert } = useAlert();
  const { setMessages } = useMessages();
  const history = useHistory();
  const [producto, setProducto] = useState(null);
  const [empresa, setEmpresa] = useState(null);
  const [sucursal, setSucursal] = useState(null);
  const [propiedades, setPropiedades] = useState({ ...defaultPropiedades });

  const handleCategoriaChange = (e) => {
    setCategoriaSeleccionada(e.target.value);
    setProducto(null);
  };

  const changeCantidad = async (_cantidad, pos) => {
    //validacion de productoinventario >  _cantidad && _cantidad > 0
    if ((_cantidad && _cantidad > 0) || _cantidad === "") {
      let copyData = [...productos];
      copyData[pos].cantidad = _cantidad;
      setProductos([...copyData]);
    }
  };
  const agregado = () => {
    if (producto) {
      const refaccionExistente = productos.some(
        (c) => c.producto.idProducto === producto.idProducto && c.categoria === categoriaSeleccionada
      );

      if (producto.inventario) {
        if (producto != null) {
          let copyProductos = [...productos];
          let validacion = productos.some((product) => {
            return product.idProducto == producto.idProducto;
          });
          if (validacion) {
            showAlert({
              message: "producto repetido",
              severity: "warning",
            });
            return;
          }
          copyProductos.push({ ...producto, cantidad: 1 });
          setProductos([...copyProductos]);
        } else {
          producto == null &&
            showAlert({
              message: "Campo de Producto Vacío",
              severity: "warning",
            });
          return;
        }
      } else {
        if (propiedades.operacion == "Salidas") {
          showAlert({
            message: `${producto.nombreProducto} sin inventario disponible`,
            severity: "warning",
          });
        } else {
          if (producto != null) {
            let copyProductos = [...productos];
            let validacion = productos.some((product) => {
              return product.idProducto == producto.idProducto;
            });
            if (validacion) {
              showAlert({
                message: "producto repetido",
                severity: "warning",
              });
              return;
            }
            copyProductos.push({ ...producto, cantidad: 1 });
            setProductos([...copyProductos]);
          } else {
            producto == null &&
              showAlert({
                message: "Campo de Producto Vacío",
                severity: "warning",
              });
            return;
          }
        }
      }
    }
  };
  const addProducto = async () => {
    //validacion de producto cantidad > 0
    if (propiedades?.operacion == "Salidas") {
      if (empresa != null) {
        agregado();
      } else {
        showAlert({
          message: "Campo de Empresa Vacío",
          severity: "warning",
        });
        return;
      }
    } else {
      agregado();
    }
  };

  const deleteProduct = (id) => {
    let arr = [...productos];
    productos.map((product, i) => {
      if (product.idProducto == id) {
        arr.splice(i, 1);
      }
    });
    setProductos([...arr]);
  };

  return (
    <Vista>
      <div style={{ width: "50%" }}>
        <div className={OrdenCompraStyles.container}>
          <div className={OrdenCompraStyles.top}>
            <div className={OrdenCompraStyles.title}>
              <p>Refacciones</p>
            </div>
          </div>
          <div className={OrdenCompraStyles.formArea}>
            <div className={OrdenCompraStyles.formDataContainer}>
              <Grid
                container
                direction="row"
                sx={{ width: "100%", height: "100%" }}
                columns={{ xs: 12, sm: 12, md: 12 }}
              >
                {/* <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    height: "92px",
                    padding: "0 10px 0 10px",
                  }}
                >
                  <Input
                    required={true}
                    typeInput={"formSelect"}
                    valor={operacion}
                    nombre="Operación"
                    disabled
                    data={["Salida"]}
                  />
                </Grid> */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px",
                    },
                  }}
                  className={OrdenCompraStyles.inputArea}
                >
                  <AutocompleteDeluxe
                    labelToShow="Empresas"
                    labelProp="nombre"
                    nombre="empresas"
                    value={empresa}
                    setDato={setEmpresa}
                    background={"#FFFFFF"}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px",
                    },
                  }}
                  className={OrdenCompraStyles.inputArea}
                >
                  <AutocompleteDeluxe
                    labelToShow="Sucursales"
                    labelProp="nombreSucursal"
                    nombre="sucursales"
                    value={sucursal}
                    setDato={setSucursal}
                    background={"#FFFFFF"}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{
                    "@media (min-width: 600px)": {
                      paddingRight: "10px",
                    },
                  }}
                  className={OrdenCompraStyles.inputArea}
                >
                  <Input
                    typeInput={"formSelect"}
                    valor={categoriaSeleccionada}
                    nombre="Categoría"
                    onChange={handleCategoriaChange}
                    data={[
                      "SUSPENSION",
                      "SERVICIO MOTOR",
                      "DIFERENCIAL",
                      "FRENOS",
                      "NEUMATICOS",
                      "INTERIORES",
                      "REFACCIONES ELECTRICAS",
                      "REFACCIONES",
                      "ADITAMENTOS",
                      "TRANSMISION",
                    ]}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "92px",
                    padding: "0 10px 0 10px",
                  }}
                >
                  {categoriaSeleccionada && (
                    <AutocompleteDeluxe
                      labelToShow="Productos"
                      labelProp="nombreProducto"
                      nombre="productos"
                      value={producto}
                      setDato={setProducto}
                      filtro={{ categoria: categoriaSeleccionada }}
                      background={"#FFFFFF"}
                      key={categoriaSeleccionada}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    "@media (max-width: 600px)": {
                      alignItems: "center",
                    },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    height: "92px",
                    padding: "0 10px 0 10px",
                  }}
                >
                  <Button
                    sx={{
                      width: "100%",
                      height: "41px",
                      backgroundColor: "#157CC1",
                      borderRadius: "10px",
                      display: "flex",
                      alignItems: "center",
                      textTransform: "none",
                      marginBottom: "7px",
                    }}
                    onClick={addProducto}
                    variant="contained"
                  >
                    <BiPlus className={EstilosButton.icon} />
                    <p className={EstilosButton.font}>Agregar Producto</p>
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className={OrdenCompraStyles.formAreaTable}>
            <Paper
              sx={{
                "@media (max-width: 600px)": { width: "98%", minHeight: "50%" },
                width: "100%",
                overflow: "hidden",
              }}
            >
              <TableContainer sx={{ maxHeight: 320 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow className={OrdenCompraStyles?.tableHead}>
                      <TableCell
                        className="headColor"
                        sx={{ fontFamily: "poppins" }}
                      >
                        #
                      </TableCell>
                      <TableCell
                        className="headColor"
                        sx={{ fontFamily: "poppins" }}
                      >
                        Nombre
                      </TableCell>
                      <TableCell
                        className="headColor"
                        sx={{ fontFamily: "poppins" }}
                      >
                        Cantidad
                      </TableCell>
                      <TableCell
                        className="headColor"
                        sx={{ fontFamily: "poppins" }}
                      >
                        Eliminar
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {productos &&
                      productos.map((product, i) => (
                        <TableRow
                          className={OrdenCompraStyles?.tableRow}
                          key={i}
                        >
                          <TableCell
                            className="rowColor"
                            sx={{ fontFamily: "poppins" }}
                          >
                            {i + 1}
                          </TableCell>
                          <TableCell
                            className="rowColor"
                            sx={{
                              fontFamily: "poppins",
                              lineHeight: "13px",
                            }}
                          >
                            {product?.nombreProducto}
                          </TableCell>
                          <TableCell className="rowColor">
                            <div className={OrdenCompraStyles?.quantity}>
                              <div
                                className={OrdenCompraStyles?.quantityContainer}
                                style={{ width: "40%" }}
                              >
                                <div className={OrdenCompraStyles?.quantityArea}>
                                  <Input
                                    typeInput="onlyInput"
                                    style={{
                                      textAlign: "center",
                                      padding: "0px",
                                      width: "30px",
                                    }}
                                    typeFormat="number"
                                    valor={product.cantidad}
                                    onChange={(e) => {
                                      changeCantidad(e.target.value, i);
                                    }}
                                  />
                                </div>
                                <IconButton
                                  onClick={() =>
                                    changeCantidad(
                                      parseInt(product.cantidad) + 1,
                                      i
                                    )
                                  }
                                >
                                  <AddIcon />
                                </IconButton>
                                <IconButton
                                  onClick={() =>
                                    changeCantidad(
                                      parseInt(product.cantidad) - 1,
                                      i
                                    )
                                  }
                                >
                                  <RemoveIcon />
                                </IconButton>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() =>
                                deleteProduct(product.idProducto)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
          <div
            className={OrdenCompraStyles?.buttonAreaContainer}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <div className={OrdenCompraStyles?.buttonContainer}>
              <LoadingButton
                style={
                  productos.length <= 0
                    ? { color: "white", background: "#AAAAAA" }
                    : productos.some((prod) => prod.cantidad === "")
                      ? { color: "white", background: "#AAAAAA" }
                      : {}
                }
                disabled={
                  productos.length <= 0 ||
                  productos.some((prod) => prod.cantidad === "")
                }
                sx={{
                  width: "170px",
                  height: "50px",
                  backgroundColor: "#157CC1",
                  fontFamily: "poppins",
                  borderRadius: "10px",
                  border: "none",
                  fontWeight: "500",
                  fontSize: "14px",
                  letterSpacing: "0.07em",
                  color: "#FFFFFF",
                  marginLeft: "2px",
                  "@media (min-width: 600px)": {
                    marginBottom: "6px",
                  },
                  "&:hover": {
                    backgroundColor: "#1565c0",
                    cursor: "pointer",
                  },
                }}
                className={OrdenCompraStyles?.buttonSave}
                onClick={() =>
                  guardarRefaccionesOrdenServicio(
                    productos,
                    empresa,
                    sucursal,
                    operacion
                  )
                }
              >
                GUARDAR REFACCIONES
              </LoadingButton>
            </div>
          </div>
          <div
            className={OrdenCompraStyles?.buttonAreaContainer}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <div className={OrdenCompraStyles?.buttonContainer}>
              <LoadingButton
                sx={{
                  width: "170px",
                  height: "50px",
                  backgroundColor: "#157CC1",
                  fontFamily: "poppins",
                  borderRadius: "10px",
                  border: "none",
                  fontWeight: "500",
                  fontSize: "14px",
                  letterSpacing: "0.07em",
                  color: "#FFFFFF",
                  marginLeft: "2px",
                  "@media (min-width: 600px)": {
                    marginBottom: "6px",
                  },
                  "&:hover": {
                    backgroundColor: "#1565c0",
                    cursor: "pointer",
                  },
                }}
                className={OrdenCompraStyles?.buttonSave}
                onClick={() => finalizarOrdenServicio()}
              >
                FINALIZAR ORDEN
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
    </Vista>
  );
};

export default Diagnosticos;
