import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import PrivateRoute from "../components/PrivateRoute";
import Home from "../views/home";
import PrivateHeader from "../containers/PrivateHeader";
import enlaces from "../const/enlaces";
import SideBar from "../components/SideBar";

const PrivateRoutes = () => {
  const [rutas, setRutas] = useState();

  const getRutas = () => {
    let preRutas = [];
    enlaces.forEach((enlace, i) => {
      preRutas.push(
        <PrivateRoute
          exact
          key={i}
          component={enlace.vista}
          path={"/" + enlace.permiso}
          permisoCheck={enlace.permiso}
        />
      );
      preRutas.push(
        <PrivateRoute
          exact
          key={i}
          component={enlace.detail}
          path={"/" + enlace.permiso + "/crear"}
          permisoCheck={enlace.permiso}
        />
      );
      preRutas.push(
        <PrivateRoute
          exact
          key={i}
          component={enlace.detail}
          path={"/" + enlace.permiso + "/editar/:id"}
          permisoCheck={enlace.permiso}
        />
      );
    });
    setRutas([...preRutas]);
  };

  useEffect(() => {
    getRutas();
  }, []);

  return (
    <Router>
      <SideBar />
      <PrivateHeader />
      <Switch>
        {rutas}
        <Route exact path="/inicio" component={Home} />
        <Route path="*" component={() => <Redirect to="/inicio" />} />
      </Switch>
    </Router>
  );
};

export default PrivateRoutes;
