import React, { useState } from "react";
import TableCustom from "../../components/TableCustom";
import _ from "lodash";
import Input from "../../components/Input";
import { Grid } from "@mui/material";
import Vista from "../../components/Vista";
import dateComponent from "../../components/dateComponent";

const busquedaDefault = {
  nombreSucursal: "",
  fechaInicio: dateComponent.getFirstDayOfYear(),
  fechaFinal: dateComponent.getActualDay(),
};

let dataTable = {
  nombreSucursal: {
    nombre: "Nombre Sucursal",
    content: (e) => {
      return e.nombreSucursal;
    },
  },
  direccion: {
    nombre: "Dirección",
    content: (e) => {
      return e.direccion;
    },
  },
  datos: {
    nombre: "Correo",
    content: (e) => {
      return e.correo;
    },
  },
};
const Sucursal = ({ onClick, onKeyDown, ...props }) => {
  const [busqueda, setBusqueda] = useState({ ...busquedaDefault });

  return (
    <Vista>
      <TableCustom
        tipo={"idSucursal"}
        busqueda={busqueda}
        setBusqueda={setBusqueda}
        dataTable={dataTable}
        buscador={[
          <Grid item xs={10} sm={8} sx={{ marginRight: "10px", height: "41px" }} key={1}>
            <Input
              value={busqueda?.nombreSucursal}
              placeholder={"Nombre Sucursal"}
              typeInput={"onlyInput"}
              nombreBusqueda="nombreSucursal"
              onChange={(e) =>
                setBusqueda({ ...busqueda, nombreSucursal: e.target.value })
              }
            />
          </Grid>,
        ]}
        permisoCheck={"sucursales"}
        nombreBusqueda={"nombreSucursal"}
      />
    </Vista>
  );
};

export default Sucursal;
