import { Grid, Button, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import React, { useEffect, useState, } from "react";
import Input from "../../components/Input";
import Vista from "../../components/Vista";
import { productDetails } from "../../utils/styles";
import { AiOutlinePercentage } from "react-icons/ai";
import { MdOutlineAttachMoney } from "react-icons/md";
import FirebaseStorage from "../../services/firebase";
import moment from "moment";
import UploadPhotoComponent from "../../components/UploadPhotoComponent";
import { NavLink } from "react-router-dom";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import Servo from "../../services/servo";
import { useHistory } from "react-router-dom";
import { useMessages } from "../../hooks/useMessages";
import { LoadingButton } from "@mui/lab";
import AutocompleteDeluxe from "../../components/AutocompleteDelux";
import { EstilosButton } from "../../utils/styles";
import { BiPlus } from "react-icons/bi";
import { IconButton } from "rsuite";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAlert } from "../../hooks/useAlert";

let vs = productDetails();
const Detail = ({ match }) => {
  const history = useHistory();
  const formDefault = {
    nombreProducto: "",
    categoria: "",
    marca: "",
    uMedida: "",
    descripcion: "",
    orden: "",
    porcentaje: "",
    precioProveedor: "",
    precioPublico: "",
    comentario: "",
    numParte: "",
    claveSat: "",
    archivo: {
      nombre: "foto",
      tipo: "",
      nombreFirebase: "",
      url: "",
      folder: "",
    },
    idCarro: "",
  };

  //AREA DE DECLARACIONES
  const { setMessages } = useMessages();
  const permisoCheck = "productos";
  const [permiso] = useLocalStorage("permiso", null);
  const [formDetails, setformDetails] = useState({ ...formDefault });
  const id = match?.params?.id;
  const editado = !!id;
  const [editarState, setEditarState] = useState(false);
  const [file, setFile] = useState(null);
  const [tipoImagen, setTipoImagen] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [carro, setCarro] = useState(null);
  const [carros, setCarros] = useState([]);
  const { showAlert } = useAlert();

  //SE SUBE LA IMAGEN A FIREBASE IDENTIFICANDO SI ES CANVA O SOURCE
  const uploadFile = async () => {
    if (tipoImagen != null) {
      //DETECTANDO QUE ES CAMARA
      if (tipoImagen == "camera") {
        let lastName = formDetails.archivo.nombreFirebase;
        const blob = await new Promise((resolve) =>
          file.current.toBlob(resolve)
        );
        const folder = "Archivos";
        const ranm = Math.floor(Math.random() * 1000);
        const nombreFirebase =
          "Producto_" +
          formDetails?.nombreProducto +
          "_" +
          moment(new Date()).format("DD_MM_YYYY_HH_mm_ss") +
          "_" +
          ranm;

        let url = await FirebaseStorage.upload(
          folder + "/" + nombreFirebase,
          blob
        );
        if (lastName) {
          await FirebaseStorage.delete(folder, lastName);
        }
        return { nombreFirebase, folder, tipo: blob?.type, url };
      } else if (tipoImagen == "src") {
        //DETECTANDO SI ES UN SOURCE
        const folder = "Archivos";
        const ranm = Math.floor(Math.random() * 1000);
        const nombreFirebase =
          "Producto_" +
          formDetails?.nombreProducto +
          "_" +
          moment(new Date()).format("DD_MM_YYYY_HH_mm_ss") +
          "_" +
          ranm;
        let url = await FirebaseStorage.upload(
          folder + "/" + nombreFirebase,
          file
        );
        formDetails.archivo.nombre = file?.name;
        return { nombreFirebase, folder, tipo: file?.type, url };
      }
    }
  };

  //SE EXTRAEN LOS PRODUCTOS
  const getProducto = async () => {
    setLoading(true);
    let res = await Servo.get("productos?idProducto=" +
      id +
      "&expand=archivo,aplica.carros");
    console.log('Respuesta de la API:', res);
    if (res?.cuerpo?.resultado?.length > 0) {
      const producto = res?.cuerpo?.resultado[0];
      console.log('Producto obtenido:', producto);
      setformDetails({ ...formDetails, ...producto });
      setCarros(producto?.aplica);
      console.log('Carros aplicados:', producto?.aplica);
    }
    setLoading(false);
  };

  const addCarro = async () => {
    if (carro != null) {
      let copyCarros = [...carros];
      let validacion = carros.some((car) => car.idCarro === carro.idCarro);
      if (validacion) {
        showAlert({
          message: "Carro repetido",
          severity: "warning",
        });
        return;
      }
      copyCarros.push(carro);
      setCarros(copyCarros);
      showAlert({
        message: "Carro agregado correctamente",
        severity: "success",
      });
    } else {
      showAlert({
        message: "Campo de Carro Vacío",
        severity: "warning",
      });
      return;
    }
  };

  const deleteCarro = (idCarro) => {
    let copyCarros = [...carros];
    copyCarros = copyCarros.filter(car => car.idCarro !== idCarro);
    setCarros(copyCarros);
    showAlert({
      message: "Carro eliminado correctamente",
      severity: "success",
    });
  };

  useEffect(() => {
    if (editado) {
      getProducto();
    }
  }, [editado]);

  //SE GUARDAN LOS PRODUCTOS
  const guardar = async () => {
    setLoadingButton(true);

    if (editarState) {
      let firebaseInfo = await uploadFile();
      formDetails.archivo.folder = firebaseInfo.folder;
      formDetails.archivo.nombreFirebase = firebaseInfo.nombreFirebase;
      formDetails.archivo.tipo = firebaseInfo.tipo;
      formDetails.archivo.url = firebaseInfo.url;
    }

    formDetails.aplica = carros; // Asegúrate de que este dato esté correcto

    let res = await Servo.post("productos/guardar", formDetails, "productos", true, history);
    if (res?.cuerpo) {
      setMessages(res?.cuerpo);
    }
    setLoadingButton(false);
  };

  let productDetails = vs();
  return (
    <Vista>
      <div className={productDetails.screenSize}>
        <div className={productDetails.top}>
          {(permiso[permisoCheck] & 1) === 1 ? (
            <NavLink exact to={"/productos"}>
              « Volver a productos
            </NavLink>
          ) : (
            <p>« Volver a productos</p>
          )}
        </div>
        <div className={productDetails.top}>
          <div className={productDetails.title}>
            {editado ? <p>Editar Producto</p> : <p>Nuevo Producto</p>}
          </div>
        </div>
        <div className={productDetails.formArea}>
          <Grid container direction="row" columns={{ xs: 12, sm: 12, md: 12 }}>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              sx={{
                "@media (min-width: 600px)": {
                  paddingRight: "10px",
                },
              }}
              className={productDetails.inputArea}>
              <Input
                typeInput={"form"}
                valor={formDetails?.numParte}
                setValor={setformDetails}
                placeholder=""
                nombre="Codigo / Número de parte"
                required="true"
                onChange={(e) => {
                  setformDetails({
                    ...formDetails,
                    numParte: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              sx={{
                "@media (min-width: 600px)": {
                  paddingRight: "10px",
                },
              }}
              className={productDetails.inputArea}>
              <Input
                typeInput={"form"}
                valor={formDetails?.nombreProducto}
                setValor={setformDetails}
                placeholder=""
                nombre="Nombre del producto"
                required="true"
                onChange={(e) => {
                  setformDetails({
                    ...formDetails,
                    nombreProducto: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              sx={{
                "@media (min-width: 600px)": {
                  paddingRight: "10px",
                },
              }}
              className={productDetails.inputArea}>
              <Input
                typeInput={"formSelect"}
                valor={formDetails?.claveSat}
                nombre="Clave SAT"
                required="true"
                onChange={(e) =>
                  setformDetails({ ...formDetails, claveSat: e.target.value })
                }
                data={[
                  "12161600 Catalizadores",
                  "15121500 Preparados lubricantes",
                  "25171500 Limpiaparabrisas",
                  "25171600 Sistemas de descarchado y antiniebla",
                  "25171700 Sistemas de frenado y componentes",
                  "25171900 Ruedas y acabados",
                  "25172000 Componentes de sistema de suspensión",
                  "25172100 Componentes y sistemas de seguridad de vehículo",
                  "25172200 Puertas para vehículos",
                  "25172300 Ventanas y parabrisas para vehículos",
                  "25172400 Depósitos y sistemas de combustible",
                  "25172500 Neumáticos y cámaras de neumáticos",
                  "25172600 Acabados y revestimientos exterior para vehículos",
                  "25172700 Sistemas de control medioambiental",
                  "25172800 Sistemas y componentes hidráulicos",
                  "25172900 Iluminación exterior para vehículos",
                  "25173000 Iluminación interior para vehículos",
                  "25173300 Sistemas de control principal",
                  "25173700 Controles de emisión y de escape",
                  "25173900 Componentes eléctricos",
                  "25174000 Sistema de refrigerar de motor",
                  "25174400 Sistemas del interior de vehículos",
                  "25174600 Sistemas de asientos del vehículo",
                  "25174700 Componentes de ciclo no motorizado o accesorios",
                  "25174800 Componentes y sistemas de vehículos especializados",
                  "25174900 Aisladores y amortiguadores de vibración de vehículos",
                  "25175000 Sistemas de cargado eléctrico de vehículos",
                  "25181600 Chasis automotriz",
                  "25181700 Remolques para productos y materiales",
                  "25191500 Sistemas y equipo de apoyo para transporte aéreo",
                  "25191600 Sistemas y equipo de apoyo para transporte espacial",
                  "25191700 Equipo para el mantenimiento de vehículo",
                  "25191800 Equipo de medición y prueba de vehículos",
                  "26101100 Motores eléctricos de corriente alterna AC",
                  "26101200 Motores eléctricos de corriente directa DC",
                  "26101300 Motores no eléctricos",
                  "26101400 Componentes de motores o generadores",
                  "26101500 Motores",
                  "26101700 Accesorios y componentes de motor",
                  "26111500 Transmisión de energía cinética",
                  "26111600 Generadores de potencia",
                  "26111700 Baterías, pilas y accesorios",
                  "26111800 Componentes de la transmisión",
                  "26111900 Embragues",
                  "26112000 Piezas y accesorios de embragues",
                  "26121800 Cable automotriz",
                  "31181700 Embalajes y empaquetaduras",
                  "31181800 Cubiertas automotrices",
                  "40101800 Equipo de calefacción y piezas y accesorios",
                  "40161500 Filtros",
                  "81101700 Ingeniería eléctrica y electrónica",
                  "84111500 Servicios contables",
                  "78181500 Servicios de mantenimiento y reparación de vehículos",
                ].sort((a, b) => {
                  const numA = parseInt(a, 10);
                  const numB = parseInt(b, 10);

                  return numA - numB;
                })}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              sx={{
                "@media (min-width: 600px)": {
                  paddingRight: "10px",
                },
              }}
              className={productDetails.inputArea}>
              <Input
                typeInput={"formSelect"}
                valor={formDetails?.marca}
                nombre="Marca"
                onChange={(e) =>
                  setformDetails({ ...formDetails, marca: e.target.value })
                }
                data={[
                  "DURALAST",
                  "CARTEK",
                  "MONROE",
                  "SYD",
                  "GRC",
                  "MOTORCRAFT",
                  "ECOM",
                  "VALVOLINE",
                  "BARDAHAL",
                  "AUTOLITE",
                  "NGK",
                  "VALUCRAFT",
                  "MOBIL",
                  "EURO FRICTION",
                  "WAGNER",
                  "GATES",
                  "TMK",
                  "LUK",
                  "WD-40",
                  "MOOG",
                  "LTH",
                  "REIDEN",
                  "TOYOTA",
                  "RAM",
                  "FORD",
                  "MITSUBICHI",
                  "MERCEDES-BENZ",
                  "BFGOODRICH",
                  "MICHELIN",
                  "BRIDGESTON",
                  "UNIROYAL",
                  "ROADMASTER",
                  "CHAMPION",
                  "DENSO",
                  "STP",
                  "MOPAR",
                  "TTX",
                  "RALOY",
                  "CARDONE",
                  "MELLING",
                  "JAC",
                  "ECCO",
                  "RACINGTEC",
                  "LED",
                  "PRESTONE",
                  "FRAM",
                  "LUCAS",
                  "SIBSA",
                  "HAYAKAWA",
                  "FILREF",
                  "BFGOODRICH",
                  "MASTREK",
                  "TORQUE",
                  "SAILUN",
                  "GOPRO",
                  ,]}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              sx={{
                "@media (min-width: 600px)": {
                  paddingRight: "10px",
                },
              }}
              className={productDetails.inputArea}>
              <Input
                typeFormat="text100"
                typeInput={"formSelect"}
                valor={formDetails?.uMedida}
                required="true"
                nombre="Unidad de medida"
                onChange={(e) =>
                  setformDetails({ ...formDetails, uMedida: e.target.value })
                }
                data={["KIT", "PIEZA", "GALON", "LITROS", "METROS"]}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              sx={{
                "@media (max-width: 600px)": {
                  paddingLeft: "10px",
                },
              }}
              className={productDetails.inputArea}>
              <Input
                typeFormat="numberDecimal"
                typeInput={"formIcon"}
                valor={formDetails.precioProveedor}
                setValor={setformDetails}
                placeholder=""
                nombre="Costo"
                required="true"
                onChange={(e) => {
                  setformDetails({
                    ...formDetails,
                    precioProveedor: e.target.value,
                    precioPublico:
                      e.target.value && formDetails?.porcentaje
                        ? parseFloat(e.target.value) +
                        parseFloat(
                          (e.target.value * formDetails?.porcentaje) / 100
                        )
                        : 0,
                  });
                }}
                Icon={MdOutlineAttachMoney}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={8}
              sx={{
                "@media (min-width: 600px)": {
                  paddingRight: "10px",
                },
              }}
              className={productDetails.inputArea}>
              <Input
                typeInput={"form"}
                valor={formDetails.descripcion}
                setValor={setformDetails}
                placeholder=""
                nombre="Descripción del producto"
                required="true"
                onChange={(e) => {
                  setformDetails({
                    ...formDetails,
                    descripcion: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              md={4}
              sx={{
                "@media (min-width: 600px)": {
                  paddingRight: "10px",
                },
              }}
              className={productDetails.inputArea}>
              <Input
                typeFormat="percent"
                typeInput={"formIcon"}
                valor={formDetails.porcentaje}
                placeholder=""
                nombre="Porcentaje"
                required="true"
                onChange={(e) => {
                  setformDetails({
                    ...formDetails,
                    porcentaje: e.target.value,
                    precioPublico:
                      e.target.value && formDetails?.precioProveedor
                        ? parseFloat(formDetails?.precioProveedor) +
                        parseFloat(
                          (formDetails?.precioProveedor *
                            parseFloat(e.target.value)) /
                          100
                        )
                        : 0,
                  });
                }}
                Icon={AiOutlinePercentage}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              sx={{
                "@media (min-width: 600px)": {
                  paddingRight: "10px",
                },
              }}
              className={productDetails.inputArea}>
              <Input
                disabled={true}
                typeFormat="numberDecimal"
                typeInput={"formIcon"}
                required="true"
                valor={formDetails.precioPublico}
                // setValor={setformDetails}
                placeholder=""
                nombre="Precio público"
                Icon={MdOutlineAttachMoney}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              sx={{
                "@media (min-width: 600px)": {
                  paddingRight: "10px",
                },
              }}
              className={productDetails.inputArea}>
              <Input
                typeInput={"formSelect"}
                valor={formDetails?.orden}
                setValor={setformDetails}
                nombre="Stock minimo"
                required="true"
                onChange={(e) =>
                  setformDetails({ ...formDetails, orden: e.target.value })
                }
                data={["0", "5", "10", "15", "20"]}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              sx={{
                "@media (min-width: 600px)": {
                  paddingRight: "10px",
                },
              }}
              className={productDetails.inputArea}>
              <Input
                typeInput={"formSelect"}
                valor={formDetails?.categoria}
                nombre="Categoría"
                required="true"
                onChange={(e) =>
                  setformDetails({ ...formDetails, categoria: e.target.value })
                }
                data={[
                  "SUSPENSION",
                  "SERVICIO MOTOR ",
                  "DIFERENCIAL",
                  "FRENOS",
                  "NEUMATICOS",
                  "INTERIORES",
                  "REFACCIONES ELECTRICAS",
                  "REFACCIONES",
                  "ADITAMENTOS",
                  "TRANSMISION",
                ]}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              sx={{
                "@media (min-width: 600px)": {
                  paddingRight: "10px",
                },
              }}
              className={productDetails.inputArea}>
              <Input
                typeInput={"form"}
                valor={formDetails.comentario}
                setValor={setformDetails}
                placeholder=""
                nombre="Comentario"
                onChange={(e) => {
                  setformDetails({
                    ...formDetails,
                    comentario: e.target.value,
                  });
                }}
              />
            </Grid>
          </Grid>

          <Grid container direction="row" columns={{ xs: 12, sm: 12, md: 12 }}>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "92px",
                padding: "10px",
              }}>
              <AutocompleteDeluxe
                labelToShow="Carros en los que aplica la refaccion"
                labelProp="economico"
                nombre="carros"
                value={carro}
                setDato={setCarro}
                backgroundColor={"#FFFFFF"}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={4}
              sx={{
                "@media (max-width: 600px)": {
                  alignItems: "center",
                },
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                height: "92px",
                padding: "0 10px 0 10px",
              }}>
              <Button
                sx={{
                  width: "100%",
                  height: "41px",
                  backgroundColor: "#157CC1",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  textTransform: "none",
                  marginBottom: "7px",
                }}
                onClick={addCarro}
                variant="contained">
                <BiPlus className={EstilosButton.icon} />
                <p className={EstilosButton.font}>Agregar Carro</p>
              </Button>
            </Grid>

            <Paper sx={{ "@media (max-width: 600px)": { width: "98%", minHeight: "50%" }, width: "100%", overflow: "hidden" }}>
              <TableContainer sx={{ maxHeight: 320 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead style={{ backgroundColor: "#157CC1" }}>
                    <TableRow >
                      <TableCell className="headColor"
                        sx={{ fontFamily: "poppins" }}>#</TableCell>
                      <TableCell className="headColor"
                        sx={{ fontFamily: "poppins" }}>No. Economico</TableCell>
                      <TableCell className="headColor"
                        sx={{ fontFamily: "poppins" }}>Placas</TableCell>
                      <TableCell className="headColor"
                        sx={{ fontFamily: "poppins" }}>marca</TableCell>
                      <TableCell className="headColor"
                        sx={{ fontFamily: "poppins" }}>modelo</TableCell>
                      <TableCell className="headColor"
                        sx={{ fontFamily: "poppins" }}>Año</TableCell>
                      <TableCell className="headColor"
                        sx={{ fontFamily: "poppins" }}>Eliminar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {carros && carros.length > 0 && carros.map((car, index) => (
                      <TableRow key={index}>
                        <TableCell className="rowColor" sx={{ fontFamily: "poppins" }}>{index + 1}</TableCell>
                        <TableCell className="rowColor" sx={{ fontFamily: "poppins", lineHeight: "13px" }}>{car?.carros?.economico}</TableCell>
                        <TableCell className="rowColor" sx={{ fontFamily: "poppins", lineHeight: "13px" }}>{car?.carros?.placas}</TableCell>
                        <TableCell className="rowColor" sx={{ fontFamily: "poppins", lineHeight: "13px" }}>{car?.carros?.marca}</TableCell>
                        <TableCell className="rowColor" sx={{ fontFamily: "poppins", lineHeight: "13px" }}>{car?.carros?.modelo}</TableCell>
                        <TableCell className="rowColor" sx={{ fontFamily: "poppins", lineHeight: "13px" }}>{car?.carros?.year}</TableCell>
                        <TableCell className="rowColor" sx={{ fontFamily: "poppins", lineHeight: "13px" }}>
                          <IconButton onClick={() => deleteCarro(car.idCarro)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>

          </Grid>
        </div>
        <UploadPhotoComponent
          value={file}
          setValue={setFile}
          setTipo={setTipoImagen}
          editarState={setEditarState}
          editar={
            formDetails.archivo.nombreFirebase ? formDetails.archivo.url : ""
          }
        />
        <div className={productDetails.buttonArea}>
          {(permiso[permisoCheck] & 2) === 2 ? (
            <LoadingButton
              loading={loadingButton}
              onClick={guardar}
              sx={{
                width: "170px",
                height: "50px",
                backgroundColor: "#157CC1",
                fontFamily: "poppins",
                borderRadius: "10px",
                border: "none",
                fontWeight: "500",
                fontSize: "14px",
                letterSpacing: "0.07em",
                color: "#FFFFFF",
                marginLeft: "2px",
                "@media (min-width: 600px)": {
                  marginBottom: "6px",
                },
                "&:hover": {
                  backgroundColor: "#1565c0",
                  cursor: "pointer",
                },
              }}>
              GUARDAR
            </LoadingButton>
          ) : (
            <LoadingButton
              onClick={guardar}
              disabled
              sx={{
                width: "170px",
                height: "50px",
                backgroundColor: "#157CC1",
                fontFamily: "poppins",
                borderRadius: "10px",
                border: "none",
                fontWeight: "500",
                fontSize: "14px",
                letterSpacing: "0.07em",
                color: "#FFFFFF",
                marginLeft: "2px",
                "@media (min-width: 600px)": {
                  marginBottom: "6px",
                },
                "&:hover": {
                  backgroundColor: "#1565c0",
                  cursor: "pointer",
                },
              }}>
              GUARDAR
            </LoadingButton>
          )}
        </div>
      </div>
    </Vista>
  );
};

export default Detail;
