import React, { useRef, useState } from "react";
import TableCustom from "../../components/TableCustom";
import _ from "lodash";
import Input from "../../components/Input";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Vista from "../../components/Vista";
import dateComponent from "../../components/dateComponent";
import SettingsIcon from "@mui/icons-material/Settings";
import { useModal } from "../../hooks/useModal";
import Permiso from "../../components/Permiso";
import { UsuariosStyle } from "../../utils/styles";
import { LoadingButton } from "@mui/lab";
import { useHistory } from "react-router-dom";
import servo from "../../services/servo";
import { useMessages } from "../../hooks/useMessages";

const US = UsuariosStyle();
const Usuario = ({ onClick, onKeyDown, ...props }) => {
  const UsuariosStyle = US();
  const busquedaDefault = {
    correo: "",
    fechaInicio: dateComponent.getFirstDayOfYear(),
    fechaFinal: dateComponent.getActualDay(),
  };
  const [busqueda, setBusqueda] = useState({ ...busquedaDefault });
  const [permisoLoading, setPermisoLoading] = useState(false);
  const [usuario, setUsuario] = useState("");
  const { setOpen, setContainerModal } = useModal();
  const ref = useRef(null);
  const history = useHistory();
  const { setMessages } = useMessages();

  const guardarPermisos = async (idUsuario) => {
    setPermisoLoading(true);
    let datos = getPermisosFormat(getPermisosFrom());
    let formato = { ...datos, idUsuario };
    console.log(formato);
    let res = await servo.post(
      "permiso" + "/guardar",
      formato,
      "Usuarios",
      true,
      history
    );
    if (res?.cuerpo) {
      setMessages(res?.cuerpo);
    }
    setPermisoLoading(false);
    setOpen(false);
  };

  const getPermisosFrom = () => {
    const nuevosPermisos = [];
    Array.from(ref.current.children).forEach((row) => {
      nuevosPermisos.push({
        nombre: row.children[0].innerHTML,
        ver: row.children[1].children[0].children[0].children[0].checked,
        agregar: row.children[2].children[0].children[0].children[0].checked,
        modificar: row.children[3].children[0].children[0].children[0].checked,
        eliminar: row.children[4].children[0].children[0].children[0].checked,
      });
    });
    return nuevosPermisos;
  };

  const getPermisosFormat = (_permisosToFormat) => {
    let permisosFormated = {};
    _permisosToFormat.forEach((row) => {
      let totalPermisos = 0;
      if (row?.ver) {
        totalPermisos += 1;
      }
      if (row?.agregar) {
        totalPermisos += 2;
      }
      if (row?.modificar) {
        totalPermisos += 4;
      }
      if (row?.eliminar) {
        totalPermisos += 8;
      }
      permisosFormated[row.nombre] = totalPermisos;
    });

    return permisosFormated;
  };
  const openModal = (permisos, idUsuario) => {
    let permisosKeys = Object.keys(permisos);
    let container = (
      <div style={{ minHeight: "80%" }}>
        <div className={UsuariosStyle?.odin}>
          <Paper sx={{ maxHeight: "90%" }}>
            <TableContainer sx={{ maxHeight: "80%" }}>
              <Table
                sx={{ maxHeight: "50%" }}
                stickyHeader
                aria-label="sticky table">
                <TableHead>
                  <TableRow className={UsuariosStyle?.tableHead}>
                    <TableCell className="headColor">Permiso</TableCell>
                    <TableCell className="headColor">Ver</TableCell>
                    <TableCell className="headColor">Agregar</TableCell>
                    <TableCell className="headColor">Modificar</TableCell>
                    <TableCell className="headColor">Deshabilitar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody ref={ref}>
                  {permisosKeys &&
                    permisosKeys?.length > 0 &&
                    permisosKeys.map((permiso, i) => {
                      if (permiso != "idUsuario") {
                        return (
                          <Permiso
                            nombre={permiso}
                            permiso={permisos[permiso]}
                            color={i % 2 == 0 ? "#F2F2F2" : "#D9D9D9"}
                            llave={i}
                          />
                        );
                      } else {
                        setUsuario(permisos[permiso]);
                      }
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
        <div className={UsuariosStyle?.buttons}>
          <Button onClick={() => setOpen(false)} className="cancelar">
            Cancelar
          </Button>
          <LoadingButton
            loading={permisoLoading}
            onClick={() => guardarPermisos(idUsuario)}
            className="guardar">
            Guardar
          </LoadingButton>
        </div>
      </div>
    );
    setContainerModal(container);
  };

  let dataTable = {
    nombreProducto: {
      nombre: "Nombre Completo",
      content: (row) => {
        return row.nombreCompleto;
      },
    },
    categoria: {
      nombre: "Correo",
      content: (row) => {
        return row.correo;
      },
    },
    permisos: {
      nombre: "Permisos",
      content: (row) => {
        return (
          <IconButton
            onClick={(e) => {
              // duplicarPermisos(row?.permisos[0]);
              openModal(row?.permisos[0], row?.idUsuario);
            }}>
            <SettingsIcon />
          </IconButton>
        );
      },
    },
  };

  return (
    <Vista>
      {!permisoLoading ? (
        <TableCustom
          tipo={"idUsuario"}
          busqueda={busqueda}
          setBusqueda={setBusqueda}
          dataTable={dataTable}
          buscador={[
            <Grid
              item
              xs={10}
              sm={8}
              sx={{
                "@media (max-width: 600px)": {
                  margin: "0px",
                },
                marginRight: "10px",
                height: "41px",
              }}
              key={1}>
              <Input
                value={busqueda?.correo}
                placeholder={"Correo"}
                typeInput={"onlyInput"}
                nombreBusqueda="correo"
                onChange={(e) =>
                  setBusqueda({ ...busqueda, correo: e.target.value })
                }
              />
            </Grid>,
          ]}
          expand={"permisos"}
          permisoCheck={"usuarios"}
          nombreBusqueda={"nombreCompleto"}
        />
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            margin: "100px 0",
          }}>
          <CircularProgress />
        </div>
      )}
    </Vista>
  );
};

export default Usuario;
