// COMPONENTE REUTILIZABLE DE COMPONENTE PARA SUBIR FOTOS
import React from "react";
import { FiUpload } from "react-icons/fi";
import { BsFillCameraFill } from "react-icons/bs";
import { ImCross } from "react-icons/im";
import { CircularProgress, IconButton } from "@mui/material";
import { productDetails } from "../utils/styles";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";

let vs = productDetails();
const UploadPhotoComponent = ({
  setValue = () => { },
  setTipo = () => { },
  value,
  editarState = false,
  editar = "",
}) => {
  let productDetails = vs();
  const videoRef = useRef(null);
  const photoRef = useRef(null);

  const [hasPhoto, setHasPhoto] = useState(false);
  const [hasVideo, setHasVideo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasOptionSelect, setHasOptionSelect] = useState("default");

  useEffect(() => {
    return () => {
      window.localStream.getVideoTracks().forEach((track) => track.stop());
    };
  }, []);

  // FUNCION PARA EMPEZAR A GRABAR EN EL COMPONENTE
  const getVideo = async () => {
    setLoading(true);
    const _stream = await navigator.mediaDevices.getUserMedia({
      video: { width: 237, height: 200, facingMode: "environment" },
    });
    window.localStream = _stream;
    handleStream(_stream);
    setLoading(false);
  };

  // FUNCION PARA PARAR O EMPEZAR GRABACION
  const handleStream = (_stream) => {
    videoRef.current.srcObject = _stream;
    _stream == null ? videoRef.current.pause() : videoRef.current.play();
  };

  // FUNCION PARA TOMAR FOTO
  const takePhoto = () => {
    const width = 237;
    const height = width / (16 / 9);

    let video = videoRef.current;
    let photo = photoRef.current;

    photo.width = width;
    photo.height = height;
    let ctx = photo.getContext("2d");
    ctx.drawImage(video, 0, 0, width, height);
    setHasPhoto(true);
    editarState(true);
    setValue(photoRef);
  };

  // FUNCION PARA PARAR DE GRABAR
  const stopVideo = (video) => {
    video.current.srcObject.getVideoTracks().forEach((track) => {
      track.stop();
      video.current.srcObject.removeTrack(track);
    });
  };

  // FUNCION PARA ACTIVAR LA GRABACION
  const showPhoto = () => {
    getVideo();
  };

  return (
    <div className={productDetails.photoArea}>
      <div className={productDetails.photoButtons}>
        <div className={productDetails.photoButton}>
          <IconButton
            onClick={(e) => {
              if (hasVideo === true) {
                stopVideo(videoRef);
                setHasVideo(false);
                setHasOptionSelect("src");
                setTipo("src");
                setValue(null);
              } else {
                setHasOptionSelect("src");
                setTipo("src");
                setValue(null);
              }
            }}
            sx={{
              width: "100%",
              height: "100%",
              backgroundColor: "#157CC1",
              borderRadius: "10px 10px 0px 0px",
              "@media (max-width: 600px)": {
                borderRadius: "10px",
              },
            }}>
            <FiUpload
              style={{ color: "#FFFFFF", width: "25%", height: "65%" }}
            />
          </IconButton>
        </div>
        <div className={productDetails.photoButton}>
          <IconButton
            onClick={(e) => {
              setHasOptionSelect("camera");
              setTipo("camera");
              setHasVideo(true);
              showPhoto();
              setValue(null);
            }}
            sx={{
              width: "100%",
              height: "100%",
              backgroundColor: "#157CC1",
              borderRadius: "10px 10px 0px 0px",
              "@media (max-width: 600px)": {
                borderRadius: "10px",
              },
            }}>
            <BsFillCameraFill
              style={{ color: "#FFFFFF", width: "20%", height: "70%" }}
            />
          </IconButton>
        </div>
      </div>
      <div className={productDetails.imageArea}>
        {hasOptionSelect == "camera" ? (
          //DIVISION PARA COMPONENTE DE ENCENDIDO DE CAMERA
          <div className={productDetails.imageComponent}>
            <div className={productDetails.image}>
              {loading && (
                <div
                  className={productDetails.image}
                  style={{ position: "absolute", alignItems: "center" }}>
                  <CircularProgress />
                </div>
              )}
              <canvas
                className={
                  hasPhoto
                    ? hasVideo
                      ? productDetails.hiddenCanva
                      : productDetails.showCanva
                    : productDetails.hiddenCanva
                }
                ref={photoRef}></canvas>
              <video
                className={
                  hasVideo
                    ? productDetails.showVideo
                    : productDetails.hiddenVideo
                }
                ref={videoRef}></video>
            </div>
            <div className={productDetails.buttonImage}>
              {/* CAMERA BUTTON */}
              <IconButton
                onClick={(e) => {
                  takePhoto();
                  setHasVideo(false);
                  stopVideo(videoRef);
                }}
                disabled={loading}
                sx={{
                  width: "40%",
                  height: "100%",
                  backgroundColor: "#157CC1",
                  borderRadius: "10px",
                }}>
                <BsFillCameraFill
                  style={{ color: "#FFFFFF", width: "100%", height: "80%" }}
                />
              </IconButton>
              {/* REUPLOAD BUTTON */}
              <IconButton
                onClick={(e) => {
                  setHasOptionSelect("camera");
                  setTipo("camera");
                  setHasVideo(true);
                  showPhoto();
                  setValue(null);
                }}
                disabled={loading}
                sx={{
                  width: "40%",
                  height: "100%",
                  backgroundColor: "#e71633",
                  borderRadius: "10px",
                }}>
                <ImCross
                  style={{ color: "#FFFFFF", width: "20%", height: "70%" }}
                />
              </IconButton>
            </div>
          </div>
        ) : hasOptionSelect == "src" ? (
          //DIVISION PARA COMPONENTE DE SRC
          <div className={productDetails.imageComponent}>
            <div className={productDetails.imageSRC}>
              {value ? (
                <img
                  className={productDetails.imageSRC}
                  src={value ? URL.createObjectURL(value) : ""}
                />
              ) : (
                <></>
              )}
            </div>
            <div className={productDetails.buttonImage}>
              <IconButton
                aria-label="upload picture"
                component="label"
                sx={{
                  width: "40%",
                  height: "100%",
                  backgroundColor: "#157CC1",
                  borderRadius: "10px",
                }}>
                <input
                  onChange={(e) => {
                    editarState(true);
                    setValue(e?.target?.files[0]);
                  }}
                  hidden
                  accept="image/*"
                  type="file"
                />
                <FiUpload
                  style={{ color: "#FFFFFF", width: "20%", height: "70%" }}
                />
              </IconButton>
            </div>
          </div>
        ) : (
          //DIVISION PARA COMPONENTE DE DEFAULT
          <div className={productDetails.imageComponent}>
            {editar != "" ? (
              <div className={productDetails.imageSRC}>
                <img
                  className={productDetails.imageSRC}
                  src={editar != "" ? editar : ""}
                />
              </div>
            ) : (
              <div className={productDetails.imageSRC}></div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadPhotoComponent;
