import React from "react";
import { Document, Page, Text, View, StyleSheet, pdf } from "@react-pdf/renderer";
import moment from "moment";

const PdfOrdenServicio = ({ data }) => {
  let vehiculo = data.checklists?.datoschecklists?.carros?.economico;
  let kilometraje = data.checklists?.datoschecklists?.carros?.kilometraje;
  let fechaInicio = moment(data.fechaInicio).format("L");
  let fechaFinal = moment(data.fechaFinal).format("L");
  let turno = data.checklists?.datoschecklists?.turno;
  let tiempoRepacion = data.checklists?.datoschecklists?.tiempoReparacion;
  let operador = data.checklists?.datoschecklists?.operador;
  let tipoMantenimiento = data.checklists?.datoschecklists?.tipoMantenimiento;
  let observaciones = data.checklists?.datoschecklists?.observaciones;
  let refacciones = data.checklists?.datoschecklists?.refacciones;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header */}
        <View style={styles.header}>
          <View style={styles.headerLeft}>
            <Text style={styles.title}>ZONDA Automotive Services</Text>
          </View>
          <View style={styles.headerRight}>
            <Text style={styles.ordenDeTrabajo}>ORDEN DE TRABAJO</Text>
            <Text>No. {data.numeroOrden}</Text>
          </View>
        </View>

        {/* Info Section */}
        <View style={styles.infoSection}>
          <View style={styles.infoRow}>
            <Text style={styles.infoLabel}>VEHÍCULO:</Text>
            <Text style={styles.infoValue}>{vehiculo}</Text>
            <Text style={styles.infoLabel}>ODÓMETRO:</Text>
            <Text style={styles.infoValue}>{kilometraje}</Text>
          </View>
          <View style={styles.infoRow}>
            <Text style={styles.infoLabel}>FECHA INICIO:</Text>
            <Text style={styles.infoValue}>{fechaInicio}</Text>
            <Text style={styles.infoLabel}>FECHA TÉRMINO:</Text>
            <Text style={styles.infoValue}>{fechaFinal}</Text>
          </View>
          <View style={styles.infoRow}>
            <Text style={styles.infoLabel}>TURNO:</Text>
            <Text style={styles.infoValue}>{turno}</Text>
            <Text style={styles.infoLabel}>TIEMPO DE REPARACIÓN:</Text>
            <Text style={styles.infoValue}>{tiempoRepacion}</Text>
          </View>
          <View style={styles.infoRow}>
            <Text style={styles.infoLabel}>NOMBRE OPERADOR:</Text>
            <Text style={styles.infoValue}>{operador}</Text>
            <Text style={styles.infoLabel}>MANTENIMIENTO REALIZADO INTERVINIENDO EQUIPO:</Text>
            <Text style={styles.infoValue}>{tipoMantenimiento}</Text>
          </View>
        </View>

        {/* Maintenance Section */}
        <View style={styles.maintenanceSection}>
          <Text style={styles.maintenanceTitle}>TIPO DE MANTENIMIENTO (EN CASO DE SER MÁS DE UNO, INDICAR TIEMPO)</Text>
          <View style={styles.maintenanceRow}>
            <View style={styles.checkbox}>
              <Text>☐</Text>
              <Text style={styles.checkboxLabel}>FRENOS</Text>
            </View>
            <View style={styles.checkbox}>
              <Text>☐</Text>
              <Text style={styles.checkboxLabel}>S. ELEC</Text>
            </View>
            <View style={styles.checkbox}>
              <Text>☐</Text>
              <Text style={styles.checkboxLabel}>TREN M.</Text>
            </View>
            <View style={styles.checkbox}>
              <Text>☐</Text>
              <Text style={styles.checkboxLabel}>SOLDADURA</Text>
            </View>
            <View style={styles.checkbox}>
              <Text>☐</Text>
              <Text style={styles.checkboxLabel}>A/C</Text>
            </View>
            <View style={styles.checkbox}>
              <Text>☐</Text>
              <Text style={styles.checkboxLabel}>CRISTALES</Text>
            </View>
          </View>
          <View style={styles.maintenanceRow}>
            <View style={styles.checkbox}>
              <Text>☐</Text>
              <Text style={styles.checkboxLabel}>TRANSMISIÓN</Text>
            </View>
            <View style={styles.checkbox}>
              <Text>☐</Text>
              <Text style={styles.checkboxLabel}>PREVENTIVO</Text>
            </View>
            <View style={styles.checkbox}>
              <Text>☐</Text>
              <Text style={styles.checkboxLabel}>MOTOR</Text>
            </View>
            <View style={styles.checkbox}>
              <Text>☐</Text>
              <Text style={styles.checkboxLabel}>LLANTA</Text>
            </View>
            <View style={styles.checkbox}>
              <Text>☐</Text>
              <Text style={styles.checkboxLabel}>CORR. PROG.</Text>
            </View>
            <View style={styles.checkbox}>
              <Text>☐</Text>
              <Text style={styles.checkboxLabel}>INDICAR</Text>
            </View>
          </View>
        </View>

        {/* Observations Section */}
        <View style={styles.observationsSection}>
          <Text style={styles.observationsTitle}>CAUSA O FALLA</Text>
          <Text style={styles.observationsText}>{observaciones}</Text>
          <Text style={styles.observationsTitle}>CORRECCIÓN DE FALLA</Text>
          <Text style={styles.observationsText}>{data.correccionFalla}</Text>
        </View>

        {/* Parts Section */}
        <View style={styles.partsSection}>
          <Text style={styles.partsTitle}>REFACCIONES UTILIZADAS</Text>
          <View style={styles.partsTable}>
            {refacciones.map((refaccion, index) => (
              <View style={styles.partsRow} key={index}>
                <Text style={styles.partsCell}>{index + 1}</Text>
                <Text style={styles.partsCell}>{refaccion}</Text>
              </View>
            ))}
          </View>
        </View>

        {/* Signatures Section */}
        <View style={styles.signaturesSection}>
          <View style={styles.signatureBlock}>
            <Text style={styles.signatureLabel}>FIRMA OPERADOR</Text>
          </View>
          <View style={styles.signatureBlock}>
            <Text style={styles.signatureLabel}>FIRMA SUPERVISOR</Text>
          </View>
          <View style={styles.signatureBlock}>
            <Text style={styles.signatureLabel}>FIRMA TÉCNICO</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 10,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  headerLeft: {
    width: '60%',
  },
  headerRight: {
    width: '40%',
    alignItems: 'flex-end',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  ordenDeTrabajo: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 4,
  },
  infoSection: {
    marginBottom: 20,
  },
  infoRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  infoLabel: {
    width: '20%',
    fontWeight: 'bold',
  },
  infoValue: {
    width: '30%',
  },
  maintenanceSection: {
    marginBottom: 20,
  },
  maintenanceTitle: {
    fontWeight: 'bold',
    marginBottom: 10,
  },
  maintenanceRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  checkbox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkboxLabel: {
    marginLeft: 5,
  },
  observationsSection: {
    marginBottom: 20,
  },
  observationsTitle: {
    fontWeight: 'bold',
    marginBottom: 5,
  },
  observationsText: {
    marginBottom: 10,
  },
  partsSection: {
    marginBottom: 20,
  },
  partsTitle: {
    fontWeight: 'bold',
    marginBottom: 10,
  },
  partsTable: {
    borderWidth: 1,
    borderColor: '#000',
  },
  partsRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: '#000',
  },
  partsCell: {
    width: '50%',
    padding: 5,
    borderRightWidth: 1,
    borderColor: '#000',
  },
  signaturesSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  signatureBlock: {
    width: '30%',
    alignItems: 'center',
    borderTopWidth: 1,
    borderColor: '#000',
    paddingTop: 5,
  },
  signatureLabel: {
    fontWeight: 'bold',
  },
});

const blobToExport = async (data) => {
  return await pdf(<PdfOrdenServicio data={data} />).toBlob();
};

export default blobToExport;