/* eslint-disable array-callback-return */
////////////////////////////////////////////////////////////////////////
//                            Hooks                                   //
////////////////////////////////////////////////////////////////////////
import { useEffect, useState } from 'react'
import FirebaseService from "../services/firebase";
import { useAlert } from "../hooks/useAlert";
import LaunchIcon from '@mui/icons-material/Launch';
////////////////////////////////////////////////////////////////////////
//                          Components                                //
////////////////////////////////////////////////////////////////////////
import {
  Grid,
  Button,
  Card,
  Box,
  CardContent,
  CardMedia,
  CircularProgress,
  Typography,
  IconButton,
} from "@mui/material";
import Swal from "sweetalert2";
////////////////////////////////////////////////////////////////////////
//                            Styles                                  //
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
//                             Icons                                  //
////////////////////////////////////////////////////////////////////////
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
////////////////////////////////////////////////////////////////////////
//                             Extra                                  //
////////////////////////////////////////////////////////////////////////

const Multifile = ({
  files,
  setFiles,
  firebaseFolder,
  loading,
  setLoading,
  ...props
}) => {
  ////////////////////////////////////////////////////////////////////////
  //                              Vars                                  //
  ////////////////////////////////////////////////////////////////////////

  const [current, setCurrent] = useState(null);
  const { showAlert } = useAlert();
  ////////////////////////////////////////////////////////////////////////
  //                           Funcions Def                             //
  ////////////////////////////////////////////////////////////////////////
  const upload = async _files => {
    setLoading(true);
    if (_files) {
      if (_files?.length > 0) {
        let u = (Array.from(_files).length - 1);
        Array.from(_files).forEach(async (_file, i) => {
          if (_file) {
            const ranm = Math.floor(Math.random() * 10000);
            const ranm2 = Math.floor(Math.random() * 10000);
            const ranm3 = Math.floor(Math.random() * 10000);
            const ranm4 = Math.floor(Math.random() * 10000);
            let nombreFirebase = ranm + "_" + ranm3 + "_" + _file.name + "_" + ranm4 + "_" + ranm2;
            const pathFirebase = firebaseFolder + "/" + nombreFirebase;
            let url = await FirebaseService.upload(pathFirebase, _file);
            if (i => u) {
              setLoading(false);
            }
            setCurrent({
              file: _file,
              nombre: _file.name,
              url: url,
              tipo: _file.type,
              nombreFirebase,
            })
          }
        });
      }
    }
  }

  useEffect(() => {
    if (current) {
      setFiles([...files, { ...current }]);
    }
  }, [current])

  useEffect(() => {
    console.log(loading)
  }, [loading])

  const deleteFile = async _file => {
    if (_file) {
      const text = ("¿Estás seguro de que deseas borrar el archivo " + _file.nombre + "?").toUpperCase();
      const res = await Swal.fire({
        title: text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#25607A",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, borrarlo!",
        cancelButtonText: "Cancelar",
      });

      if (res.isConfirmed) {
        showAlert({
          message: "Espera un momento.....",
          severity: "warning",
        });
        setLoading(true);
        await FirebaseService.deleteFile(firebaseFolder + "/", _file.firebaseName);
        let posi;
        const copyFiles = [...files];
        files.map((e, index) => {
          if (e.firebaseName === _file.firebaseName) {
            posi = index;
          }
        });
        copyFiles.splice(posi, 1);
        setFiles([...copyFiles]);
        showAlert({
          message: "Borrado Correctamente",
          severity: "success",
        });
        setLoading(false);
      }
    }
  }
  ////////////////////////////////////////////////////////////////////////
  //                            Hooks Def                               //
  ////////////////////////////////////////////////////////////////////////

  return (
    <>
      <div style={{ width: "70%" }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Button disabled={loading} variant="contained" component="label" sx={{ color: "white", width: "100%" }}>
              <AddIcon sx={{ margin: "0 10px" }} /> SUBIR ARCHIVO
              <input
                onChange={async (e) => {
                  await upload(e.target.files);
                }}
                hidden
                accept="application/pdf,image/*"
                type="file"
                multiple
              />
            </Button>
          </Grid>
          {
            !loading ?
              <Grid item xs={12}>
                {
                  !loading
                    ?
                    files && (
                      files.length > 0 && files.map(f => {
                        return (
                          <Card sx={{ display: 'flex', margin: "20px" }}>
                            <CardMedia
                              component="img"
                              sx={{ width: 151 }}
                              image={
                                f.tipo === "application/pdf"
                                  ?
                                  "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/Campanas%2Fdefaultpdf.png?alt=media&token=7e79fc7c-aec2-41ce-aa0c-e1f9c6e11745"
                                  : f.url
                              }
                              alt="Live from space album cover"
                            />
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", width: "100%" }} >
                              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <CardContent sx={{ flex: '1 0 auto' }}>
                                  <Typography component="div" variant="h5">
                                    {f.nombre}
                                  </Typography>
                                  <Typography variant="subtitle1" color="text.secondary" component="div">
                                    {f.tipo}
                                  </Typography>
                                  <IconButton onClick={e => {
                                    window.open(f.url, '_blank');
                                  }}>
                                    <LaunchIcon />
                                  </IconButton>
                                </CardContent>
                              </Box>
                              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: "center", padding: "0 20px" }}>
                                <DeleteIcon onClick={e => { deleteFile(f) }} />
                              </Box>
                            </Box>
                          </Card>
                        )
                      })
                    )
                    :
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", margin: "20px" }}>
                      <CircularProgress />
                    </div>
                }
              </Grid>
              :
              <div>
                <CircularProgress />
              </div>
          }
        </Grid>
      </div>
    </>
  );
}
export default Multifile;