import React, { useState } from "react";
import TableCustom from "../../components/TableCustom";
import _ from "lodash";
import { Grid, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import dateComponent from "../../components/dateComponent";
import Input from "../../components/Input";
import Vista from "../../components/Vista";
import { useModal } from "../../hooks/useModal";

const busquedaDefault = {
  nombre: "",
  razonSocial: "",
  rfc: "",
  fechaInicio: dateComponent.getFirstDayOfYear(),
  fechaFinal: dateComponent.getActualDay(),
};

const Proveedor = ({ onClick, onKeyDown, ...props }) => {
  const [busqueda, setBusqueda] = useState({ ...busquedaDefault });
  const { setContainerModal, setOpen } = useModal();
  const [modalData, setModalData] = useState({ tipo: "", data: [] });

  const handleVisibilityClick = (tipo, dataList) => {
    setModalData({ tipo, data: dataList });
    setContainerModal(
      <div style={{ textAlign: "center" }}>
        {tipo === "telefono" && (
          <>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr style={{ backgroundColor: "#157CC1", color: "#FFFFFF" }}>
                  <th style={{ padding: "10px" }}>Teléfono del Proveedor</th>
                </tr>
              </thead>
              <tbody>
                {dataList.map((e, i) => (
                  <tr key={i} style={{ backgroundColor: i % 2 === 0 ? "#FFFFFF" : "#D9D9D9" }}>
                    <td style={{ padding: "10px", textAlign: "center" }}>{e.telefono}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
        {tipo === "correo" && (
          <>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr style={{ backgroundColor: "#157CC1", color: "#FFFFFF" }}>
                  <th style={{ padding: "10px" }}>Correos del Proveedor</th>
                </tr>
              </thead>
              <tbody>
                {dataList.map((e, i) => (
                  <tr key={i} style={{ backgroundColor: i % 2 === 0 ? "#FFFFFF" : "#D9D9D9" }}>
                    <td style={{ padding: "10px", textAlign: "center" }}>{e.correo}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
        {tipo === "bancos" && (
          <>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr style={{ backgroundColor: "#157CC1", color: "#FFFFFF" }}>
                  <th style={{ padding: "10px" }}>Bancos</th>
                  <th style={{ padding: "10px" }}>Cuentas</th>
                  <th style={{ padding: "10px" }}>CLABES</th>
                  <th style={{ padding: "10px" }}>Modo de Pago</th>
                </tr>
              </thead>
              <tbody>
                {dataList.map((e, i) => (
                  <tr key={i} style={{ backgroundColor: i % 2 === 0 ? "#FFFFFF" : "#D9D9D9" }}>
                    <td style={{ padding: "10px", textAlign: "center" }}>{e.banco}</td>
                    <td style={{ padding: "10px", textAlign: "center" }}>{e.numeroTarjeta}</td>
                    <td style={{ padding: "10px", textAlign: "center" }}>{e.clabe}</td>
                    <td style={{ padding: "10px", textAlign: "center" }}>{e.modoPago}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>

        )}
      </div>
    );
    setOpen(true);
  };

  const dataTable = {
    nombre: {
      nombre: "Nombre del proveedor",
      content: (e) => e.nombre,
    },
    razonSocial: {
      nombre: "Razon Social",
      content: (e) => e.razonSocial,
    },
    rfc: {
      nombre: "RFC",
      content: (e) => e.rfc,
    },
    telefono: {
      nombre: "Telefonos",
      content: (e) => {
        const telefonos = _.get(e, "telefonos", []);
        return (
          <>
            {telefonos.length > 0 && (
              <IconButton onClick={() => handleVisibilityClick("telefono", telefonos)}>
                <VisibilityIcon />
              </IconButton>
            )}
          </>
        );
      },
    },
    correo: {
      nombre: "Correos",
      content: (e) => {
        const correos = _.get(e, "correos", []);
        return (
          <>
            {correos.length > 0 && (
              <IconButton onClick={() => handleVisibilityClick("correo", correos)}>
                <VisibilityIcon />
              </IconButton>
            )}
          </>
        );
      },
    },
    bancos: {
      nombre: "Bancos",
      content: (e) => {
        const bancos = _.get(e, "datosBancarios", []);
        return (
          <>
            {bancos.length > 0 && (
              <IconButton onClick={() => handleVisibilityClick("bancos", bancos)}>
                <VisibilityIcon />
              </IconButton>
            )}
          </>
        );
      },
    },
  };

  return (
    <Vista>
      <TableCustom
        tipo={"idProveedor"}
        busqueda={busqueda}
        setBusqueda={setBusqueda}
        dataTable={dataTable}
        buscador={[
          <Grid item xs={10} sm={8} sx={{ marginRight: "10px", height: "41px" }} key={1}>
            <Input
              value={busqueda?.razonSocial}
              placeholder={"Razón Social"}
              typeInput={"onlyInput"}
              nombreBusqueda="razonSocial"
              onChange={(e) =>
                setBusqueda({ ...busqueda, razonSocial: e.target.value })
              }
            />
          </Grid>,
        ]}
        expand={"datosBancarios,telefonos,correos"}
        permisoCheck={"proveedores"}
        nombreBusqueda={"nombre"}
      />
    </Vista>
  );
};

export default Proveedor;