import React from "react";
import { HiLocationMarker } from "react-icons/hi";
import { HiMail } from "react-icons/hi";
import { RiFacebookBoxFill } from "react-icons/ri";
import { RiInstagramFill } from "react-icons/ri";
import { RiWhatsappFill } from "react-icons/ri";
import { ContactosStyles } from "../utils/styles";

let CS = ContactosStyles();

const Ubicacion = ({ ...props }) => {
  let ContactosStyles = CS();
  return (
    <div className={ContactosStyles.ubicacion}>
      <HiLocationMarker className={ContactosStyles.icono} />
      <p>Blvd. Navarrete 80, Santa Fé, Cp.83249, Hermosillo, Sonora</p>
    </div>
  );
};

const Correo = ({ ...props }) => {
  let ContactosStyles = CS();
  return (
    <div className={ContactosStyles.correo}>
      <HiMail className={ContactosStyles.icono} />
      <p>contaco@zondabus.com.mx</p>
    </div>
  );
};

const CorreoPrivate = ({ ...props }) => {
  let ContactosStyles = CS();
  return (
    <div className={ContactosStyles.correo2}>
      <HiMail className={ContactosStyles.icono} />
      <p>contaco@zondabus.com.mx</p>
    </div>
  );
};

const Media = ({ ...props }) => {
  const goTo = (media) => {};

  let ContactosStyles = CS();
  return (
    <div className={ContactosStyles.media}>
      <RiFacebookBoxFill
        className={ContactosStyles.iconoButton}
        onClick={(e) => {
          goTo("facebook");
        }}
      />
      <RiInstagramFill
        className={ContactosStyles.iconoButton}
        onClick={(e) => {
          goTo("instagram");
        }}
      />
      <RiWhatsappFill
        className={ContactosStyles.iconoButton}
        onClick={(e) => {
          goTo("whatsapp");
        }}
      />
    </div>
  );
};

export { Ubicacion, Correo, Media, CorreoPrivate };
