import React, { useState } from "react";
import TableCustom from "../../components/TableCustom";
import _ from "lodash";
import { Button, Grid } from "@mui/material";
import Vista from "../../components/Vista";
import AutocompleteDeluxe from "../../components/AutocompleteDelux";
import dateComponent from "../../components/dateComponent";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import pdfChecklist from "../../pdfs/pdfChecklist";
import servo from "../../services/servo";
import { useMessages } from "../../hooks/useMessages";

const busquedaDefault = {
  fechaInicio: dateComponent.getFirstDayOfYear(),
  fechaFinal: dateComponent.getActualDay(),
};

const Checklists = ({ onClick, onKeyDown, ...props }) => {
  const [busqueda, setBusqueda] = useState({
    ...busquedaDefault,
  });
  const [carro, setCarro] = useState(null);
  const [empresa, setEmpresa] = useState(null);
  const [area, setArea] = useState(null);
  const { setMessages } = useMessages();
  const [loadingButton, setLoadingButton] = useState(false);

  // Define la función handleGenerarOrden aquí
  const handleGenerarOrden = async (idChecklist) => {
    // Lógica para generar la orden de servicio
    console.log("Generar Orden para el ID de checklist:", idChecklist);
    setLoadingButton(true);
    let body = {
      DatosOrdenServicio: {
        idChecklist: idChecklist,
      }
    };

    let res = await servo.post(
      "orden-servicios/guardar-datos-orden-servicio",
      body,
      "ordenServicios",
      true,
      true
    );
    if (res?.cuerpo) {
      setMessages(res?.cuerpo);
    }
    setLoadingButton(false);
  };

  let dataTable = {
    fecha: {
      nombre: "Fecha",
      content: (e) => {
        return e?.creado;
      },
    },
    placa: {
      nombre: "Placas",
      content: (e) => {
        return e?.datoschecklists?.carros?.placas;
      },
    },
    economico: {
      nombre: "Económico",
      content: (e) => {
        return e?.datoschecklists?.carros?.economico;
      },
    },
    empresa: {
      nombre: "Empresa",
      content: (e) => {
        return e?.datoschecklists?.empresas?.nombre;
      },
    },
    areaTrabajo: {
      nombre: "Proyecto",
      content: (e) => {
        return e?.datoschecklists?.areas?.nombre;
      },
    },
    pdf: {
      nombre: "PDF",
      content: (item) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}>
            <PictureAsPdfIcon
              style={{ color: "#157CC1", cursor: "pointer" }}
              onClick={async (e) => {
                let pdf = await pdfChecklist(item);
                pdf = await pdf;
                window.open(window.URL.createObjectURL(pdf), "_blank");
              }}></PictureAsPdfIcon>
          </div>
        );
      },
    },
    // generarOrden: {
    //   nombre: "Generar Orden",
    //   content: (e) => (
    //     <Button
    //       variant="contained"
    //       color="primary"
    //       onClick={() => handleGenerarOrden(e.idChecklist)}
    //     >
    //       Generar Orden
    //     </Button>
    //   ),
    // },
  };

  return (
    <Vista>
      <TableCustom
        filtroCustom={(d) => {
          if (carro && empresa && area) {
            return (
              d?.datoschecklists?.carros?.idCarro == carro?.idCarro &&
              d?.datoschecklists?.empresas?.idEmpresa == empresa?.idEmpresa &&
              d?.datoschecklists?.areas?.idArea == area?.idArea
            );
          } else if (carro && empresa) {
            return (
              d?.datoschecklists?.carros?.idCarro == carro?.idCarro &&
              d?.datoschecklists?.empresas?.idEmpresa == empresa?.idEmpresa
            );
          } else if (carro && area) {
            return (
              d?.datoschecklists?.carros?.idCarro == carro?.idCarro &&
              d?.datoschecklists?.areas?.idArea == area?.idArea
            );
          } else if (area && empresa) {
            return (
              d?.datoschecklists?.areas?.idArea == area?.idArea &&
              d?.datoschecklists?.empresas?.idEmpresa == empresa?.idEmpresa
            );
          } else if (carro) {
            return d?.datoschecklists?.carros?.idCarro == carro?.idCarro;
          } else if (empresa) {
            return (
              d?.datoschecklists?.empresas?.idEmpresa == empresa?.idEmpresa
            );
          } else if (area) {
            return d?.datoschecklists?.areas?.idArea == area?.idArea;
          } else {
            return d;
          }
        }}
        tipo={"idChecklist"}
        busqueda={busqueda}
        dataTable={dataTable}
        buscador={[
          <Grid
            sx={{
              order: "2",
              // padding: "0 5px 0 5px",
              // marginTop: "10px",
              padding: "0 5px 0 0px",
              height: "80px",
              "@media (min-width: 900px)": {
                marginTop: "0px",
                marginBottom: "10px",
                order: "0",
              },
              "@media (max-width: 600px)": {
                padding: "0",
                margin: "0",
                orden: "0",
              },
            }}
            item
            xs={12}
            sm={4}
            md={4}
            key={1}>
            <AutocompleteDeluxe
              labelToShow="Carros"
              labelProp="economico"
              nombre="carros"
              value={carro}
              setDato={setCarro}
            />
          </Grid>,
          <Grid
            sx={{
              order: "2",
              // padding: "0 5px 0 5px",
              // marginTop: "10px",
              padding: "0 5px 0 5px",
              height: "80px",
              "@media (min-width: 900px)": {
                marginTop: "0px",
                marginBottom: "10px",
                order: "0",
              },
              "@media (max-width: 600px)": {
                padding: "0",
                margin: "0",
                orden: "0",
              },
            }}
            item
            xs={12}
            sm={4}
            md={4}
            key={2}>
            <AutocompleteDeluxe
              labelToShow="Empresa"
              labelProp="nombre"
              nombre="empresas"
              value={empresa}
              setDato={setEmpresa}
            />
          </Grid>,
          <Grid
            item
            sx={{
              order: "2",
              // padding: "0 5px 0 5px",
              // marginTop: "10px",
              padding: "0px 0px 0px 5px",
              height: "80px",
              "@media (min-width: 1000px)": {
                marginTop: "0px",
                marginBottom: "10px",
                order: "0",
              },
              "@media (max-width: 600px)": {
                padding: "0",
                margin: "0",
                orden: "0",
              },
            }}
            xs={12}
            sm={4}
            md={4}
            key={3}>
            <AutocompleteDeluxe
              labelToShow="Áreas de trabajo"
              labelProp="nombre"
              nombre="areas"
              value={area}
              setDato={setArea}
            />
          </Grid>,
        ]}
        expand={
          "datoschecklists.areas,datoschecklists.carros,datoschecklists.empresas,detallechecklists,gasolinachecklists.notas,checklistsarchivos.archivos,firmachecklists.archivos"
        }
        permisoCheck={"checklists"}
        nombreBusqueda={"placa"}
      />
    </Vista>
  );
};

export default Checklists;
