//ARCHIVO PARA CREAR LAS TABLAS CHECKCOMPONENT
import React, { useEffect } from "react";
import { ChecklistStyles } from "../utils/styles";
import { Radio } from "@mui/material";
import Input from "./Input";

let vs = ChecklistStyles();
const CheckComponent = ({
  Titulo = "",
  setValue = {},
  value,
  optionsModify = "",
  typeOption = "radio",
  typeFormat = "",
  rangeOk = "",
  rangeWarning = "",
  rangeDanger = "",
}) => {
  let ChecklistStyles = vs();

  const handleChange = (event, valor = false) => {
    setValue({ ...value, [event.target.name]: valor });
  };
  const handleChangeInput = (dato, valor) => {
    setValue({ ...value, [dato]: valor });
  };
  let regex = /(\d+)/g;

  let ok = rangeOk.match(regex);
  let warning = rangeWarning.match(regex);
  let danger = rangeDanger.match(regex);
  return (
    <>
      <div className={ChecklistStyles.titleArea}>
        {/* AREA DE CON TITULO DE LA TABLA */}
        <div className={ChecklistStyles.textArea}>
          <p className={ChecklistStyles.text}>{Titulo}</p>
        </div>
        <div className={ChecklistStyles.optionsBMContainer}>
          {/* AREA CON LAS OPCIONES BIEN Y MAL */}
          {optionsModify != "" ? (
            <div className={ChecklistStyles.optionsArea}>
              <div>{optionsModify}</div>
            </div>
          ) : (
            <div className={ChecklistStyles.optionsArea}>
              <div>B</div>
              <div>M</div>
            </div>
          )}
        </div>
      </div>
      {/* CONTENEDOR PRINCIPAL DE LAS FILAS */}
      <div className={ChecklistStyles.selectsContainer}>
        <div className={ChecklistStyles.selectsArea}>
          {Object.keys(value).map((dato, i) => {
            return (
              <div className={ChecklistStyles.select} key={i}>
                {/* AREA CON NOMBRE DE LA FILA */}
                <div className={ChecklistStyles.textArea}>
                  <p className={ChecklistStyles.textOption}>
                    {dato
                      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
                        return index === 0
                          ? word.toLowerCase()
                          : " " + word.toUpperCase();
                      })
                      .replace(/\s+/g, " ")
                      .split(" ")
                      .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
                      .join(" ")}
                  </p>
                </div>
                <div className={ChecklistStyles.optionsBMContainer}>
                  {typeOption === "radio" ? (
                    <div className={ChecklistStyles.selectArea}>
                      <div>
                        <Radio
                          checked={value[dato] ? true : false}
                          onChange={(e) => {
                            handleChange(e, true);
                          }}
                          value={value[dato] ? true : false}
                          name={dato}
                        />
                      </div>
                      <div>
                        <Radio
                          checked={value[dato] == false ? true : false}
                          onChange={(e) => {
                            handleChange(e, false);
                          }}
                          value={value[dato] == false ? true : false}
                          name={dato}
                        />
                      </div>
                    </div>
                  ) : typeOption === "input" ? (
                    <div
                      className={
                        value[dato] == ""
                          ? ChecklistStyles.selectAreaInput
                          : value[dato] >= parseInt(ok[0]) &&
                            value[dato] <= parseInt(ok[1])
                            ? ChecklistStyles.selectAreaGreen
                            : value[dato] >= parseInt(warning[0]) &&
                              value[dato] <= parseInt(warning[1])
                              ? ChecklistStyles.selectAreaYellow
                              : value[dato] > parseInt(danger[0]) &&
                                value[dato] <= parseInt(danger[1])
                                ? ChecklistStyles.selectAreaRed
                                : value[dato] == 0 && ChecklistStyles.selectAreaRed
                      }>
                      <Input
                        typeInput={"onlyInput"}
                        typeFormat={typeFormat}
                        valor={value[dato]}
                        onChange={(e) => {
                          handleChangeInput(dato, e.target.value);
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
          })}
          {typeOption == "input" ? (
            <div className={ChecklistStyles.colorsArea}>
              <div className={ChecklistStyles.colors}>
                <div className={ChecklistStyles.areaColor}>
                  <div className={ChecklistStyles.okColor}>
                    <p className={ChecklistStyles.textColors}>{rangeOk}</p>
                  </div>
                </div>
                <div className={ChecklistStyles.areaColor}>
                  <div className={ChecklistStyles.warningColor}>
                    <p className={ChecklistStyles.textColors}>{rangeWarning}</p>
                  </div>
                </div>
                <div className={ChecklistStyles.areaColor}>
                  <div className={ChecklistStyles.dangerColor}>
                    <p className={ChecklistStyles.textColors}>{rangeDanger}</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default CheckComponent;
