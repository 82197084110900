import React, {
  useMemo,
  createContext,
  useState,
  useEffect,
  useContext,
} from "react";
import { useAlert } from "./useAlert";

const MessagesContext = createContext();

export function MessagesProvider(props) {
  const [messages, setMessages] = useState({});
  const { showAlert } = useAlert();
  useEffect(() => {
    if (messages?.detalle?.estatus == 200) {
      showAlert({
        message: messages?.mensaje,
        severity: messages?.color,
      });
    } else if (messages?.detalle?.estatus == 400) {
      showAlert({
        message: messages?.mensaje,
        severity: messages?.color,
      });
      if (messages?.errores) {
        let arrMessages = Object.keys(messages?.errores);
        if (arrMessages?.length > 0) {
          arrMessages?.forEach((msg) => {
            showAlert({
              message: messages?.errores[msg],
              severity: "warning",
            });
          });
        }
      }
    }
  }, [messages]);

  const memData = useMemo(() => {
    return {
      messages,
      setMessages,
    };
  }, [messages]);

  return <MessagesContext.Provider value={memData} {...props} />;
}

export function useMessages() {
  const context = useContext(MessagesContext);
  if (!context) {
    // eslint-disable-next-line no-throw-literal
    throw "error: mesages context not defined.";
  }
  return context;
}
