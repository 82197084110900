import React from "react";
import { VistaStyles } from "../utils/styles";
let VS = VistaStyles();
const Vista = ({ id, bg = false, footer = false, style, ...props }) => {
  let VistaStyles = VS();

  if (bg && footer) {
    return (
      <div
        id={id}
        className={VistaStyles.vistaFotter}
        style={{
          ...style,
          ...{
            backgroundImage:
              "url('https://firebasestorage.googleapis.com/v0/b/zonda-b0ce9.appspot.com/o/Sistema%2Fimagenbn.jpg?alt=media&token=0c7dc5d0-e95f-4f21-a48a-e67d563661e3')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            textAlign: "center",
          },
        }}>
        {props.children}
      </div>
    );
  } else if (bg) {
    return (
      <div
        id={id}
        className={VistaStyles.vista}
        style={{
          ...style,
          ...{
            backgroundImage:
              "url('https://firebasestorage.googleapis.com/v0/b/zonda-b0ce9.appspot.com/o/Sistema%2Fimagenbn.jpg?alt=media&token=0c7dc5d0-e95f-4f21-a48a-e67d563661e3')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            textAlign: "center",
          },
        }}>
        {props.children}
      </div>
    );
  } else if (footer) {
    return (
      <div style={style} className={VistaStyles.vistaFotter}>
        {props.children}
      </div>
    );
  } else {
    return (
      <div style={style} className={VistaStyles.vista2}>
        {props.children}
      </div>
    );
  }
};
export default Vista;
