import React from 'react'
import { PublicFooterStyles } from '../utils/styles'
import Button from '../components/Button'

let PFS = PublicFooterStyles();
const PublicFooter = ({ ...props }) => {
    let PublicFooterStyles = PFS();
    return (
        <div className={PublicFooterStyles.barra}>
            <Button secondary onClick={e => { }} label="SOPORTE" />
        </div>
    )
}

export default PublicFooter