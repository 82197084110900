import React from "react";
import { PublicHeaderStyles } from "../utils/styles";

let PHS = PublicHeaderStyles();
const PrivateHeader = ({ ...props }) => {
  let PublicHeaderStyles = PHS();
  return (
    <div className={PublicHeaderStyles.barra}>
    </div>
  );
};

export default PrivateHeader;
