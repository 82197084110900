import React, { useState } from "react";
import TableCustom from "../../components/TableCustom";
import _ from "lodash";
import { Grid, IconButton } from "@mui/material";
import Vista from "../../components/Vista";
import AutocompleteDeluxe from "../../components/AutocompleteDelux";
import dateComponent from "../../components/dateComponent";

const busquedaDefault = {
  fechaInicio: dateComponent.getFirstDayOfYear(),
  fechaFinal: dateComponent.getActualDay(),
};

let dataTable = {
  fecha: {
    nombre: "Fecha",
    content: (e) => {
      return e?.creado;
    },
  },
  placa: {
    nombre: "Placas",
    content: (e) => {
      return e?.datoschecklistsEmpleados?.carros?.placas;
    },
  },
  economico: {
    nombre: "Económico",
    content: (e) => {
      return e?.datoschecklistsEmpleados?.carros?.economico;
    },
  },
  empresa: {
    nombre: "Empresa",
    content: (e) => {
      return e?.datoschecklistsEmpleados?.empresas?.nombre;
    },
  },
  areaTrabajo: {
    nombre: "Proyecto",
    content: (e) => {
      return e?.datoschecklistsEmpleados?.areas?.nombre;
    },
  },
};

const ChecklistsCampamento = ({ onClick, onKeyDown, ...props }) => {
  const [busqueda, setBusqueda] = useState({
    ...busquedaDefault,
  });
  const [carro, setCarro] = useState(null);
  const [empresa, setEmpresa] = useState(null);
  const [area, setArea] = useState(null);
  const [loading, setLoading] = useState(false);

  return (
    <Vista>
      <TableCustom
        filtroCustom={(d) => {
          if (carro && empresa && area) {
            return (
              d?.datoschecklistsEmpleados?.carros?.idCarro == carro?.idCarro &&
              d?.datoschecklistsEmpleados?.empresas?.idEmpresa == empresa?.idEmpresa &&
              d?.datoschecklistsEmpleados?.areas?.idArea == area?.idArea
            );
          } else if (carro && empresa) {
            return (
              d?.datoschecklistsEmpleados?.carros?.idCarro == carro?.idCarro &&
              d?.datoschecklistsEmpleados?.empresas?.idEmpresa == empresa?.idEmpresa
            );
          } else if (carro && area) {
            return (
              d?.datoschecklistsEmpleados?.carros?.idCarro == carro?.idCarro &&
              d?.datoschecklistsEmpleados?.areas?.idArea == area?.idArea
            );
          } else if (area && empresa) {
            return (
              d?.datoschecklistsEmpleados?.areas?.idArea == area?.idArea &&
              d?.datoschecklistsEmpleados?.empresas?.idEmpresa == empresa?.idEmpresa
            );
          } else if (carro) {
            return d?.datoschecklistsEmpleados?.carros?.idCarro == carro?.idCarro;
          } else if (empresa) {
            return (
              d?.datoschecklistsEmpleados?.empresas?.idEmpresa == empresa?.idEmpresa
            );
          } else if (area) {
            return d?.datoschecklistsEmpleados?.areas?.idArea == area?.idArea;
          } else {
            return d;
          }
        }}
        tipo={"checklistsCampamento"}
        busqueda={busqueda}
        dataTable={dataTable}
        buscador={[
          <Grid
            sx={{
              order: "2",
              // padding: "0 5px 0 5px",
              // marginTop: "10px",
              padding: "0 5px 0 0px",
              height: "80px",
              "@media (min-width: 900px)": {
                marginTop: "0px",
                marginBottom: "10px",
                order: "0",
              },
              "@media (max-width: 600px)": {
                padding: "0",
                margin: "0",
                orden: "0",
              },
            }}
            item
            xs={12}
            sm={4}
            md={4}
            key={1}>
            <AutocompleteDeluxe
              labelToShow="Carros"
              labelProp="economico"
              nombre="carros"
              value={carro}
              setDato={setCarro}
            />
          </Grid>,
          <Grid
            sx={{
              order: "2",
              // padding: "0 5px 0 5px",
              // marginTop: "10px",
              padding: "0 5px 0 5px",
              height: "80px",
              "@media (min-width: 900px)": {
                marginTop: "0px",
                marginBottom: "10px",
                order: "0",
              },
              "@media (max-width: 600px)": {
                padding: "0",
                margin: "0",
                orden: "0",
              },
            }}
            item
            xs={12}
            sm={4}
            md={4}
            key={2}>
            <AutocompleteDeluxe
              labelToShow="Empresa"
              labelProp="nombre"
              nombre="empresas"
              value={empresa}
              setDato={setEmpresa}
            />
          </Grid>,
          <Grid
            item
            sx={{
              order: "2",
              // padding: "0 5px 0 5px",
              // marginTop: "10px",
              padding: "0px 0px 0px 5px",
              height: "80px",
              "@media (min-width: 1000px)": {
                marginTop: "0px",
                marginBottom: "10px",
                order: "0",
              },
              "@media (max-width: 600px)": {
                padding: "0",
                margin: "0",
                orden: "0",
              },
            }}
            xs={12}
            sm={4}
            md={4}
            key={3}>
            <AutocompleteDeluxe
              labelToShow="Áreas de trabajo"
              labelProp="nombre"
              nombre="areas"
              value={area}
              setDato={setArea}
            />
          </Grid>,
        ]}
        expand={
          "datoschecklistsEmpleados.areas,datoschecklistsEmpleados.carros,datoschecklistsEmpleados.empresas,detallechecklistsEmpleados,gasolinachecklistsEmpleados.notas,checklistsarchivosEmpleados.archivos,firmachecklistsEmpleados.archivos"
        }
        permisoCheck={"checklistsCampamento"}
        nombreBusqueda={"idCarro"}
      />
    </Vista>
  );
};
export default ChecklistsCampamento;
