import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import FullScreenLoader from "./FullScreenLoader";
import { useLocalStorage } from "../hooks/useLocalStorage";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [usuario] = useLocalStorage("usuario", null);
  const [permiso] = useLocalStorage("permiso", null);
  const { permisoCheck } = rest;
  if (!usuario || usuario === null || !permiso) return <FullScreenLoader />;
  if ((permiso[permisoCheck] & 1) === 1) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  } else {
    return <Redirect to="/inicio" />;
  }
};

PrivateRoute.propTypes = {
  location: PropTypes.object,
  component: PropTypes.func,
};

export default PrivateRoute;
