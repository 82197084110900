import React from "react";
import { ButtonStyles } from "../utils/styles";

let BS = ButtonStyles();
const Button = ({
  primary = false,
  secondary = false,
  label = "",
  onClick,
  style,
  ...props
}) => {
  let ButtonStyles = BS();
  return (
    <button
      style={style}
      className={
        primary
          ? ButtonStyles.primary
          : secondary
          ? ButtonStyles.secondary
          : ButtonStyles.default
      }
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default Button;
