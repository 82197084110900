//ARCHIVO PARA CREAR LAS TABLAS CHECKCOMPONENT
import React, { useEffect } from "react";
import { ChecklistStyles } from "../utils/styles";
import { Radio } from "@mui/material";
import Input from "./Input";

let vs = ChecklistStyles();
const CheckMinMax = ({
  Titulo = "",
  setValue = {},
  value,
  optionsModify = "",
  typeOption = "radio",
  typeFormat = "",
  rangeOk = "",
  rangeWarning = "",
  rangeDanger = "",
}) => {
  let ChecklistStyles = vs();

  const handleChange = (event, valor = false) => {
    setValue({ ...value, [event.target.name]: valor });
  };
  const handleChangeInput = (dato, valor) => {
    setValue({ ...value, [dato]: valor });
  };
  let regex = /(\d+)/g;

  let ok = rangeOk.match(regex);
  let warning = rangeWarning.match(regex);
  let danger = rangeDanger.match(regex);
  return (
    <>
      <div className={ChecklistStyles.titleArea}>
        {/* AREA DE CON TITULO DE LA TABLA */}
        <div className={ChecklistStyles.textArea}>
          <p className={ChecklistStyles.text}>{Titulo}</p>
        </div>
        <div className={ChecklistStyles.optionsBMContainer}>
          {/* AREA CON LAS OPCIONES BIEN Y MAL */}
          {optionsModify != "" ? (
            <div className={ChecklistStyles.optionsArea}>
              <div>{optionsModify}</div>
            </div>
          ) : (
            <div className={ChecklistStyles.optionsArea}>
              <div>MIN</div>
              <div>MID</div>
              <div>MAX</div>
            </div>
          )}
        </div>
      </div>
      {/* CONTENEDOR PRINCIPAL DE LAS FILAS */}
      <div className={ChecklistStyles.selectsContainer}>
        <div className={ChecklistStyles.selectsArea}>
          {Object.keys(value).map((dato, i) => {
            return (
              <div className={ChecklistStyles.select} key={i}>
                {/* AREA CON NOMBRE DE LA FILA */}
                <div className={ChecklistStyles.textArea}>
                  <p className={ChecklistStyles.textOption}>
                    {dato
                      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
                        return index === 0
                          ? word.toLowerCase()
                          : " " + word.toUpperCase();
                      })
                      .replace(/\s+/g, " ")
                      .split(" ")
                      .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
                      .join(" ")}
                  </p>
                </div>
                <div className={ChecklistStyles.optionsBMContainer}>
                  {typeOption === "radio" ? (
                    <div className={ChecklistStyles.selectArea}>
                      <div>
                        <Radio
                          checked={value[dato] === 'minimo'}
                          onChange={(e) => {
                            handleChange(e, 'minimo');
                          }}
                          value={'minimo'}
                          name={dato}
                        />
                      </div>
                      <div>
                        <Radio
                          checked={value[dato] === 'medio'}
                          onChange={(e) => {
                            handleChange(e, 'medio');
                          }}
                          value={'medio'}
                          name={dato}
                        />
                      </div>
                      {/* Nuevo radio button para el tercer valor */}
                      <div>
                        <Radio
                          checked={value[dato] === 'maximo'}
                          onChange={(e) => {
                            handleChange(e, 'maximo');
                          }}
                          value={'maximo'}
                          name={dato}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CheckMinMax;
