/* eslint-disable no-throw-literal */
import React, { createContext, useContext, useCallback, useMemo } from "react"
import Servo from "../services/servo"
import { useLocalStorage } from "./useLocalStorage"

const ProfileContext = createContext()

export function ProfileProvider(props) {
  const [permiso, setPermiso] = useLocalStorage("permiso", {})
  const [usuario, setUsuario] = useLocalStorage("usuario", {})
  const loadUsuario = useCallback(async () => {
    const usuario = await Servo.get("profile")
    if (usuario?.cuerpo?.detalle?.estatus === 200) {
      setUsuario({ ...usuario?.cuerpo?.detalle?.usuario })
      setPermiso({ ...usuario?.cuerpo?.detalle?.permiso })
      return true
    } else {
      return false
    }
  }, [])

  const memData = useMemo(() => {
    return {
      usuario,
      permiso,
      loadUsuario,
    }
  }, [usuario, permiso, loadUsuario])
  return <ProfileContext.Provider value={memData} {...props} />
}

export function useProfile() {
  const context = useContext(ProfileContext)
  if (!context) {
    throw "error: auth context not defined."
  }
  return context
}
