import React from "react";
import { InputMessageStyles } from "../utils/styles";

let IS = InputMessageStyles();
const InputMessage = ({ style, message, ...props }) => {
  let InputMessageStyles = IS();
  return (
    <div style={style} className={InputMessageStyles.inputMessage}>
      <p>{message}</p>
    </div>
  );
};

export default InputMessage;
