import React from "react";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { SlideBarStyles, InventarioStyles } from "../utils/styles";
import { useAuth } from "../hooks/useAuth";
import LogoutIcon from "@mui/icons-material/Logout";
import enlaces from "../const/enlaces";
import { NavLink } from "react-router-dom";
import { Hidden, IconButton } from "@mui/material";
import { useLocalStorage } from "../hooks/useLocalStorage";

let VS = SlideBarStyles();
let VS2 = InventarioStyles();

export default function SideBar() {
  let SlideBarStyles = VS();
  let InventarioStyles = VS2();
  const [open, setOpen] = React.useState(false);
  const { signOut } = useAuth();
  const [permiso] = useLocalStorage("permiso", null);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // EXTRACTOR DE OPCIONES PARA EL SIDEBAR
  const getNavs = (enlace, i) => {
    if (permiso && (permiso[enlace?.permiso] & 1) === 1) {
      return (
        <NavLink
          key={i}
          exact
          to={`/${enlace.permiso}`}
          onClose={handleDrawerClose}
          className={InventarioStyles.navStyle}>
          <div className={SlideBarStyles.listContainer} key={i}>
            <button onClick={handleDrawerClose} className={SlideBarStyles.listItem}>
              <p>{enlace.nombre}</p>
            </button>
          </div>
        </NavLink>
      );
    }
  };

  return (
    <>
      {open === true ? (
        <MenuIcon style={{ display: "none", height: "32px" }} />
      ) : (
        <div className={SlideBarStyles.MenuContainer}>
          <button
            className={SlideBarStyles.BotonMenu}
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
            <Hidden mdDown={true}>
              <p>MENU</p>
            </Hidden>
          </button>
        </div>
      )}

      <Drawer
        onClose={handleDrawerClose}
        sx={{
          width: "100%",
          flexShrink: 0,
          backgroundColor: "transparent",
          "& .MuiDrawer-paper": {
            width: "279px",
            boxSizing: "border-box",
            backgroundColor: "#157CC1",
            padding: "20px 0 20px 0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          },
        }}
        open={open}>
        <div>
          <div className={SlideBarStyles.CerrarMenuContainer}>
            <IconButton onClick={handleDrawerClose} sx={{ height: "32px" }}>
              <CloseIcon className={SlideBarStyles.closeIcon} />
            </IconButton>
          </div>
          <div>
            <div className={SlideBarStyles.listContainer}>
              <NavLink exact to="inicio">
                <button onClick={handleDrawerClose} className={SlideBarStyles.listItem}>
                  <p>Inicio</p>
                </button>
              </NavLink>
            </div>
            {enlaces.map((enlace, i) => {
              return getNavs(enlace, i);
            })}
          </div>
        </div>
        <div>
          <div className={SlideBarStyles.listContainer}>
            <button onClick={signOut} className={SlideBarStyles.listItem}>
              <p
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}>
                <LogoutIcon />
                Cerrar Sesión
              </p>
            </button>
          </div>
        </div>
      </Drawer>
    </>
  );
}
