import { ChecklistStyles, productDetails } from "../../utils/styles";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { Button, Grid, IconButton } from "@mui/material";
import { MdAddAPhoto } from "react-icons/md";
import { RiArrowGoBackLine } from "react-icons/ri";
import ReplayIcon from "@mui/icons-material/Replay";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useRef, useState } from "react";
import FirebaseStorage from "../../services/firebase";
import { useModal } from "../../hooks/useModal";

let vs = ChecklistStyles();
let vs2 = productDetails();
const ChecklistArchivos = ({
  checklistsEmpleadoArchivos,
  setChecklistsEmpleadoArchivos,
  uploadEvidence,
  editado,
}) => {
  let ChecklistStyles = vs();
  let productDetails = vs2();
  const videoRef = useRef(null);
  const photoRef = useRef(null);
  const [permiso] = useLocalStorage("permiso", null);
  const [hasPhoto, setHasPhoto] = useState(false);
  const [hasVideo, setHasVideo] = useState(false);
  const [showCamera, setShowCamera] = useState(false);
  const [loading, setLoading] = useState(false);
  const [photoEvidence, setPhotoEvidence] = useState();
  const { setContainerModal } = useModal();
  const [videoSize, setVideoSize] = useState({ x: 0, y: 0 });

  // FUNCION PARA EMPEZAR A GRABAR EN EL COMPONENTE
  const getVideo = async () => {
    setLoading(true);
    const _stream = await navigator.mediaDevices.getUserMedia({
      video: { facingMode: "environment" },
    });
    window.localStream = _stream;
    setVideoSize({
      x: _stream.getVideoTracks()[0].getSettings().width,
      y: _stream.getVideoTracks()[0].getSettings().height,
    });
    handleStream(_stream);
    setLoading(false);
  };

  // FUNCION PARA PARAR O EMPEZAR GRABACION
  const handleStream = (_stream) => {
    videoRef.current.srcObject = _stream;
    _stream == null ? videoRef.current.pause() : videoRef.current.play();
  };

  const stopVideo = (video) => {
    video.current.srcObject.getVideoTracks().forEach((track) => {
      track.stop();
      video.current.srcObject.removeTrack(track);
    });
  };

  // FUNCION PARA TOMAR FOTO
  const takePhoto = () => {
    let video = videoRef.current;
    let photo = photoRef.current;

    video.width = videoSize.x;
    video.height = videoSize.y;
    photo.width = videoSize.x;
    photo.height = videoSize.y;
    let ctx = photo.getContext("2d");
    ctx.drawImage(video, 0, 0, videoSize.x, videoSize.y);
    setHasPhoto(true);
    setPhotoEvidence(photoRef);
  };

  // FUNCION PARA ACTIVAR LA GRABACION
  const showPhoto = () => {
    getVideo();
  };

  const openModal = (urlImage) => {
    let container = (
      <div style={{ display: "block", width: "100%", height: "100%" }}>
        <img
          src={urlImage}
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
      </div>
    );
    setContainerModal(container);
  };

  return showCamera ? (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "red",
        top: 0,
        position: "absolute",
      }}>
      <div
        style={{
          height: "20%",
          width: "100%",
          backgroundColor: "#080808",
          "@media (min-width: 1200px)": {
            height: "10%",
          },
        }}>
        <IconButton
          onClick={(e) => {
            setHasVideo(false);
            setShowCamera(false);
            stopVideo(videoRef);
          }}
          sx={{
            position: "absolute",
            top: 60,
            left: 10,
            height: "40px",
            width: "120px",
            backgroundColor: "#007AC1",
            color: "#FFFFFF",
            fontSize: "12px",
            fontWeight: "bold",
            lineHeight: "9.5px",
            fontFamily: "poppins",
            borderRadius: "15px",
          }}>
          <RiArrowGoBackLine style={{ marginRight: "5px" }} />
          Volver
        </IconButton>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "60%",
          width: "100%",
          backgroundColor: "#101010",
          "@media (min-width: 1200px)": {
            height: "80%",
          },
        }}>
        <canvas
          className={
            hasPhoto
              ? hasVideo
                ? productDetails.hiddenCanva
                : productDetails.showCanvaPhoto
              : productDetails.hiddenCanva
          }
          ref={photoRef}></canvas>
        <video
          className={
            hasVideo
              ? productDetails.showVideoPhoto
              : productDetails.hiddenVideo
          }
          ref={videoRef}></video>
      </div>
      <div
        style={{
          display: "flex",
          height: "20%",
          width: "100%",
          backgroundColor: "#080808",
          "@media (min-width: 1200px)": {
            height: "10%",
          },
        }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "30%",
            color: "white",
          }}>
          {hasPhoto && (
            <IconButton
              onClick={(e) => {
                showPhoto();
                setHasVideo(true);
                setHasPhoto(false);
              }}
              sx={{
                width: "60px",
                height: "60px",
                backgroundColor: "#101010",
                border: "1px solid #ffffff",
              }}>
              <ReplayIcon style={{ color: "#FFFFFF" }} />
            </IconButton>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "40%",
          }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#FFFFFF",
              borderRadius: "50%",
              width: "70px",
              height: "70px",
            }}>
            <button
              onClick={(e) => {
                takePhoto();
                stopVideo(videoRef);
                setHasVideo(false);
                setHasPhoto(true);
              }}
              style={{
                backgroundColor: "#FFFFFF",
                borderRadius: "50%",
                width: "80%",
                height: "80%",
                border: "9px solid #DCDCDC",
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "30%",
            color: "white",
          }}>
          {hasPhoto && (
            <Button
              loading={loading}
              disabled={loading}
              onClick={async (e) => {
                setLoading(true);
                let res = await uploadEvidence(photoEvidence);
                if (res) {
                  let copyEvidence = checklistsEmpleadoArchivos;
                  copyEvidence.push(res);
                  setChecklistsEmpleadoArchivos([...copyEvidence]);
                }
                setLoading(false);
                stopVideo(videoRef);
                setHasPhoto(false);
                setHasVideo(false);
                setShowCamera(false);
              }}
              sx={{
                height: "40px",
                width: "120px",
                backgroundColor: "#007AC1",
                color: "#FFFFFF",
                fontSize: "12px",
                fontWeight: "bold",
                lineHeight: "9.5px",
                fontFamily: "poppins",
                borderRadius: "15px",
                "&:hover": {
                  backgroundColor: "#0073B6",
                  color: "#FFFFFF",
                  fontSize: "12px",
                  fontWeight: "bold",
                  lineHeight: "9.5px",
                  fontFamily: "poppins",
                },
              }}>
              {loading ? "Cargando..." : "Usar Foto"}
            </Button>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{ marginTop: "40px", flexWrap: "wrap", zIndex: "5" }}
      className={ChecklistStyles.componentContainer}>
      <Grid container sx={{ width: "90%" }}>
        {checklistsEmpleadoArchivos ? (
          checklistsEmpleadoArchivos.map((image, i) => {
            return !loading ? (
              <Grid
                item
                xs={12}
                sm={4}
                md={3}
                lg={2}
                key={i}
                className={ChecklistStyles.PhotoContain}>
                <div className={ChecklistStyles.PhotoArea}>
                  <IconButton
                    sx={{ position: "absolute", right: 0 }}
                    onClick={async (e) => {
                      setLoading(true);
                      await FirebaseStorage.delete(
                        "Archivos",
                        image?.nombreFirebase
                      );
                      let copyChecklistsEmpleadoArchivo = [...checklistsEmpleadoArchivos];
                      copyChecklistsEmpleadoArchivo.splice(i, 1);
                      setChecklistsEmpleadoArchivos([...copyChecklistsEmpleadoArchivo]);
                      setLoading(false);
                    }}>
                    {editado ? (
                      <div></div>
                    ) : (
                      <DeleteIcon className={ChecklistStyles.buttonDelete} />
                    )}
                  </IconButton>
                  <img
                    src={image.url}
                    className={ChecklistStyles.PhotoStyles}
                    onClick={(e) => openModal(image?.url)}
                  />
                </div>
              </Grid>
            ) : (
              <div key={i}>CARGANDO</div>
            );
          })
        ) : (
          <div></div>
        )}
      </Grid>
      <div className={ChecklistStyles.addPhotoContainer}>
        <div className={ChecklistStyles.addAreaButton}>
          <IconButton
            // disabled={checklistArchivos?.length >= 6 ? true : false}
            onClick={(e) => {
              showPhoto();
              setHasVideo(true);
              setShowCamera(true);
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: "100%",
              backgroundColor: "#007AC1",
              color: "#FFFFFF",
              fontSize: "12px",
              fontWeight: "bold",
              lineHeight: "9.5px",
              fontFamily: "poppins",
              borderRadius: "15px",
              "&:hover": {
                backgroundColor: "007AC1",
              },
            }}>
            <MdAddAPhoto
              style={{
                color: "#FCD659",
                height: "21px",
                width: "23px",
                paddingRight: "8px",
              }}
            />
            Abrir cámara
          </IconButton>
        </div>
      </div>
    </div>
  );
};
export default ChecklistArchivos;