/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-throw-literal */
import React, {
  useState,
  createContext,
  useContext,
  useCallback,
  useMemo,
} from "react"
import { auth } from "../firebase/firebaseConfig"
import { useAuthState } from "react-firebase-hooks/auth"
import { useProfile } from "./useProfile"

const AuthContext = createContext()

export function AuthProvider(props) {
  const [session, sessionLoading, sessionError] = useAuthState(auth)
  const [errorFirebase, setErrorFirebase] = useState(null)
  const [loginLoading, setLoginLoading] = useState(false)
  const { loadUsuario } = useProfile(false)

  const signIn = useCallback(async (email, password) => {
    setLoginLoading(true)
    try {
      await auth.signInWithEmailAndPassword(email, password)
      let isLoad = await loadUsuario()
      if (!isLoad) {
        signOut()
        throw "db"
      }
    } catch (e) {
      setErrorFirebase(e)
    } finally {
      setLoginLoading(false)
    }
  }, [])

  const signOut = useCallback(async () => {
    try {
      await auth.signOut()
    } catch (e) {
      console.error(e)
    }
  }, [])

  const memData = useMemo(() => {
    return {
      session,
      sessionLoading,
      userError: sessionError,
      signIn,
      signOut,
      errorFirebase,
      loginLoading,
    }
  }, [
    session,
    sessionLoading,
    sessionError,
    signIn,
    signOut,
    errorFirebase,
    loginLoading,
  ])

  return <AuthContext.Provider value={memData} {...props} />
}

export function useAuth() {
  const context = useContext(AuthContext)
  if (!context) {
    throw "error: auth context not defined."
  }
  return context
}
