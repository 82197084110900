import { IconButton, Slider } from "@mui/material";
import Input from "../../components/Input";
import { ChecklistStyles } from "../../utils/styles";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Swal from "sweetalert2";

let vs = ChecklistStyles();
const Gasolina = ({
  gasolinaInfo,
  setGasolinaInfo,
  points,
  setPoints,
  dataGas,
  setDataGas,
  imageChange,
  selected,
  image,
}) => {
  let ChecklistStyles = vs();

  let base = 26;
  let data = base + dataGas;

  const coords = (event) => {
    if (event.target.tagName != "IMG") {
      return;
    }
    const div = event.target.getBoundingClientRect();
    const x = event.clientX - div.left - 5;
    const y = event.clientY - div.top - 5;

    let condicion = points.some((point) => {
      return point.x == x && point.y == y;
    });

    if (condicion == false) {
      setPoints([
        ...points,
        {
          x,
          y,
          open: false,
          lado: x + 180 > event.target.width ? "izquierda" : "derecha",
          nota: "",
        },
      ]);
    }
  };

  const dropPoint = (pos) => {
    let copyPoints = points;
    copyPoints.splice(pos, 1);
    setPoints([...copyPoints]);
  };
  
  const erasePoints = (pos) => {
    Swal.fire({
      title: "Alerta",
      text: "¿Desea Borrar todos los puntos?",
      icon: "warning",
      confirmButtonText: "Continuar",
      confirmButtonColor: "#157CC1",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#e71633",
    }).then(async (response) => {
      if (response.isConfirmed) {
        setPoints([])
      }
    });
  };

  return (
    <div className={ChecklistStyles.componentContainerGasolina}>
      <div className={ChecklistStyles.kmContainer}>
        <div className={ChecklistStyles.kmArea}>
          <Input
            typeInput={"form"}
            valor={gasolinaInfo.kilometraje}
            typeFormat="number"
            setValor={setGasolinaInfo}
            placeholder="000000"
            nombre="Kilometraje"
            required="true"
            onChange={(e) => {
              setGasolinaInfo({
                ...gasolinaInfo,
                kilometraje: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className={ChecklistStyles.gasContainer}>
        <div className={ChecklistStyles.gasArea}>
          <div
            style={{ transform: `rotate(${data}deg)` }}
            className={ChecklistStyles.marker}>
            <div className={ChecklistStyles.markerLine}></div>
          </div>
          <div className={ChecklistStyles.photoGasArea}>
            <div className={ChecklistStyles.areaPhoto}>
              <img
                src={
                  "https://firebasestorage.googleapis.com/v0/b/zonda-b0ce9.appspot.com/o/Sistema%2Fgasolina.png?alt=media&token=2befa5b2-657f-4c9f-8f99-b9bf50bedaa3"
                }
                alt="logo"
                className={ChecklistStyles.photo}
              />
            </div>
          </div>
          <div className={ChecklistStyles.sliderContainer}>
            <div className={ChecklistStyles.sliderArea}>
              <Slider
                aria-labelledby="input-slider"
                defaultValue={0}
                value={dataGas}
                min={0}
                max={128}
                onChange={(e) => {
                  setDataGas(e.target.value);
                  setGasolinaInfo({
                    ...gasolinaInfo,
                    gasolina: parseInt((e.target.value * 100) / 128),
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={ChecklistStyles.inputAreaSelect}>
        <div className={ChecklistStyles.inputSelect}>
          <Input
            typeInput="onlyInput"
            typeFormat="gasolina"
            valor={gasolinaInfo?.gasolina != "" ? gasolinaInfo?.gasolina : ""}
            onChange={(e) => {
              setDataGas((e.target.value * (154 - 26)) / 100);
              setGasolinaInfo({
                ...gasolinaInfo,
                gasolina: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className={ChecklistStyles.carSelectorArea}>
        <div className={ChecklistStyles.containerSelect}>
          <div className={ChecklistStyles.areaSelector}>
            <div className={ChecklistStyles.boxSelector}>
              <button
                onClick={(e) => {
                  setGasolinaInfo({ ...gasolinaInfo, tipo: "pickup" });
                  imageChange("pickup");
                }}
                className={
                  selected === "pickup"
                    ? ChecklistStyles.buttonSelect
                    : ChecklistStyles.buttonUnselect
                }>
                Pickup
              </button>
            </div>
            <div className={ChecklistStyles.boxSelector}>
              <button
                onClick={(e) => {
                  setGasolinaInfo({ ...gasolinaInfo, tipo: "sedan" });
                  imageChange("sedan");
                }}
                className={
                  selected === "sedan"
                    ? ChecklistStyles.buttonSelect
                    : ChecklistStyles.buttonUnselect
                }>
                Sedan
              </button>
            </div>
            <div className={ChecklistStyles.boxSelector}>
              <button
                onClick={(e) => {
                  setGasolinaInfo({ ...gasolinaInfo, tipo: "grua" });
                  imageChange("grua");
                }}
                className={
                  selected === "grua"
                    ? ChecklistStyles.buttonSelect
                    : ChecklistStyles.buttonUnselect
                }>
                Grúa
              </button>
            </div>
            <div className={ChecklistStyles.boxSelector}>
              <button
                onClick={(e) => {
                  setGasolinaInfo({ ...gasolinaInfo, tipo: "tonelada" });
                  imageChange("tonelada");
                }}
                className={
                  selected === "tonelada"
                    ? ChecklistStyles.buttonSelect
                    : ChecklistStyles.buttonUnselect
                }>
                Tonelada
              </button>
            </div>
          </div>
        </div>

        <div className={ChecklistStyles.imageContainer}>
          <div
            className={ChecklistStyles.photoContainer}
            onClick={(e) => {
              image != "default" && coords(e);
            }}>
            {image === "default" ? (
              ""
            ) : (
              <>
                <img className={ChecklistStyles.photoModels} src={image}></img>
              </>
            )}
            {points.map((point, index) => (
              <div
                id="odin"
                disabled={true}
                onClick={(e) => {
                  if (e.target.id != "odin") {
                    return;
                  }
                  let copyPoints = [...points];
                  copyPoints[index] = {
                    ...copyPoints[index],
                    open: true,
                  };
                  setPoints([...copyPoints]);
                }}
                key={index}
                style={{
                  left: point.x,
                  top: point.y,
                }}
                className={ChecklistStyles.pointContainer}>
                {point?.open ? (
                  <div
                    className={ChecklistStyles.popup}
                    style={{
                      left: point?.lado == "izquierda" ? "-150px" : "0px",
                    }}>
                    <div className={ChecklistStyles.optionsCloseDrop}>
                      <IconButton
                        onClick={(e) => dropPoint(index)}
                        sx={{
                          right: 0,
                          top: 0,
                        }}>
                        <DeleteOutlineIcon sx={{ color: "#C11515" }} />
                      </IconButton>
                      <IconButton
                        onClick={(e) => {
                          let copyPoints = [...points];

                          copyPoints[index] = {
                            ...copyPoints[index],
                            open: false,
                          };

                          setPoints([...copyPoints]);
                        }}
                        sx={{
                          right: 0,
                          top: 0,
                        }}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                    <div className={ChecklistStyles.inputPopup}>
                      <div className={ChecklistStyles.inputComponent}>
                        <Input
                          typeFormat="text100"
                          typeInput={"form"}
                          valor={points[index].nota}
                          placeholder="Comentario"
                          onChange={(e) => {
                            let copyPoints = [...points];

                            copyPoints[index] = {
                              ...copyPoints[index],
                              nota: e.target.value,
                            };

                            setPoints([...copyPoints]);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {index + 1}
              </div>
            ))}
          </div>
        </div>
      </div>
        <div className={ChecklistStyles.DeletePointsContainer}>
          <div className={ChecklistStyles.sizeButtonDelete}>
            <button className={ChecklistStyles.buttonDelete} onClick={erasePoints}>Borrar Puntos</button>
          </div>
        </div>
        <div className={ChecklistStyles.observacionContainer}>
          <div className={ChecklistStyles.observacionArea}>
            <Input
              typeInput={"form"}
              valor={gasolinaInfo.observaciones}
              typeFormat="text"
              placeholder=""
              nombre="Observaciones"
              required="true"
              onChange={(e) => {
                setGasolinaInfo({
                  ...gasolinaInfo,
                  observaciones: e.target.value,
                });
              }}
            />
          </div>
        </div>
    </div>
  );
};
export default Gasolina;
