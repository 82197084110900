import React from "react"
import { PublicHeaderStyles } from "../utils/styles"
import { Ubicacion, Correo, Media } from "../components/Contactos"
import { Hidden } from "@mui/material"

let PHS = PublicHeaderStyles()
const PublicHeader = ({ ...props }) => {
  let PublicHeaderStyles = PHS()
  return (
    <div className={PublicHeaderStyles.barra}>
      <div className={PublicHeaderStyles.contactos}>
        <Hidden only={"xs"}>
          <Ubicacion />
          <Correo />
        </Hidden>
      </div>
      <div className={PublicHeaderStyles.media}>
        <Hidden only={"xs"}>
          <Media />
        </Hidden>
      </div>
    </div>
  )
}

export default PublicHeader
