import React, { useMemo } from "react";
import "./App.css";
import { CacheBusterProvider } from "./hooks/CacheBuster";
import { AlertProvider, useAlert } from "./hooks/useAlert";
import { AuthProvider, useAuth } from "./hooks/useAuth";
import { ProfileProvider, useProfile } from "./hooks/useProfile";
import Alert from "@mui/material/Alert";
import PrivateRoutes from "./routes/PrivateRoutes";
import PublicRoutes from "./routes/PublicRoutes";
import FullScreenLoader from "./components/FullScreenLoader";
import { MessagesProvider } from "./hooks/useMessages";
import { Collapse } from "@mui/material";
import { MessageStyles } from "./utils/styles";
import { ModalProvider, useModal } from "./hooks/useModal";
import Modal from "./components/Modal";

const MS = MessageStyles();
const RootComponent = () => {
  const { session, sessionLoading, loginLoading } = useAuth();
  const { alertas } = useAlert();
  const MessageStyles = MS();

  const AlertMemo = useMemo(
    () => (
      <>
        {alertas.length > 0 && (
          <div
            className={MessageStyles.alert}
          >
            {alertas &&
              alertas.map((alerta, i) => {
                return (
                  <Collapse key={i} in={alerta.open}>
                    <Alert
                      style={{ margin: "10px" }}
                      severity={alerta.severity}
                      elevation={6}
                      variant="filled">
                      {alerta.message}
                    </Alert>
                  </Collapse>
                );
              })}
          </div>
        )}
      </>
    ),
    [alertas]
  );

  if (sessionLoading) return <FullScreenLoader />;

  return (
    <>
      <Modal />
      {!session ? (
        <PublicRoutes />
      ) : !loginLoading ? (
        <PrivateRoutes />
      ) : (
        <FullScreenLoader />
      )}
      {AlertMemo}
    </>
  );
};

function App() {
  return (
    <CacheBusterProvider>
      <AlertProvider>
        <MessagesProvider>
          <ModalProvider>
            <ProfileProvider>
              <AuthProvider>
                <RootComponent />
              </AuthProvider>
            </ProfileProvider>
          </ModalProvider>
        </MessagesProvider>
      </AlertProvider>
    </CacheBusterProvider>
  );
}

export default App;
