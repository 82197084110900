//ARCHIVO COMPLEMENTARIO DE LA TABLA CUSTOM

import { IconButton } from "@mui/material";
import React from "react";
import { MdModeEditOutline } from "react-icons/md";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { EstilosButton } from "../utils/styles";
import { useLocalStorage } from "../hooks/useLocalStorage";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import servo from "../services/servo";
import Swal from "sweetalert2";
let vs = EstilosButton();
const Acciones = ({
  permisoCheck = "",
  id = "",
  tipo,
  isEliminado,
  recargar,
  data
}) => {
  //ALERTA PARA DESHABILITAR LO QUE ESTA EN LA FILA DE LA TABLA
  const deshabilitar = (_id) => {
    Swal.fire({
      title: "Alerta",
      text: "¿Desea deshabilitar el registro?",
      icon: "warning",
      confirmButtonText: "Continuar",
      confirmButtonColor: "#157CC1",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#e71633",
    }).then(async (response) => {
      if (response.isConfirmed) {
        await servo.get(permisoCheck + "/deshabilitar?" + tipo + "=" + id);
        recargar();
      }
    });
  };
  //ALERTA PARA HABILITAR LO QUE ESTA EN LA FILA DE LA TABLA
  const habilitar = (_id) => {
    Swal.fire({
      title: "Alerta",
      text: "¿Desea habilitar el registro?",
      icon: "question",
      confirmButtonText: "Continuar",
      confirmButtonColor: "#157CC1",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#e71633",
    }).then(async (response) => {
      if (response.isConfirmed) {
        await servo.get(permisoCheck + "/habilitar?" + tipo + "=" + id);
        recargar();
      }
    });
  };

  let EstilosButton = vs();
  const [permiso] = useLocalStorage("permiso", null);

  return (
    <div className={EstilosButton.actionsArea}>
      {
        (permisoCheck !== "ordenServicios" || data.status !== "FINALIZADO") && (
          <>
            {(permiso[permisoCheck] & 4) === 4 && (
              // SI HAY PERMISOS SE CREA EL BOTON EDITAR CON FUNCION
              <div className={EstilosButton.actionItem}>
                <NavLink exact to={"/" + permisoCheck + "/editar/" + id}>
                  <IconButton>
                    <MdModeEditOutline className={EstilosButton.actionEdit} />
                  </IconButton>
                </NavLink>
              </div>
            )}
          </>
        )
      }
      {(permiso[permisoCheck] & 8) === 8 &&
        (isEliminado === null ? (
          //SI HAY PERMISOS SE CREA EL BOTON DESHABULITAR CON FUNCION PARA DESHABILITAR
          <div className={EstilosButton.actionItem}>
            <IconButton
              onClick={(e) => {
                deshabilitar(id);
              }}>
              <RiDeleteBin5Fill className={EstilosButton.actionDelete} />
            </IconButton>
          </div>
        ) : (
          //SI HAY PERMISOS SE CREA EL BOTON HABILITAR CON FUNCION PARA HABILITAR
          <div className={EstilosButton.actionItem}>
            <IconButton
              onClick={(e) => {
                habilitar(id);
              }}>
              <RestoreFromTrashIcon className={EstilosButton.actionUndelete} />
            </IconButton>
          </div>
        ))}
    </div>
  );
};

export default Acciones;
