import { Switch, TableCell, TableRow } from "@mui/material";
import { UsuariosStyle } from "../utils/styles";

const US = UsuariosStyle();
const Permiso = ({ nombre, permiso, color, llave }) => {
  const UsuariosStyle = US();

  return (
    <TableRow
      className={UsuariosStyle?.tableBody}
      sx={{ background: color }}
      key={llave}>
      <TableCell
        className="bodyColor"
        sx={{ color: "#717171", fontWeight: "700" }}>
        {nombre}
      </TableCell>
      <TableCell className="bodyColor">
        <Switch
          defaultChecked={(permiso & 1) === 1}
        />
      </TableCell>
      <TableCell className="bodyColor">
        <Switch defaultChecked={(permiso & 2) === 2} />
      </TableCell>
      <TableCell className="bodyColor">
        <Switch defaultChecked={(permiso & 4) === 4} />
      </TableCell>
      <TableCell className="bodyColor">
        <Switch defaultChecked={(permiso & 8) === 8} />
      </TableCell>
    </TableRow>
  );
};

export default Permiso;
