//rework
import firebase from "firebase/app";

class FirebaseService {
  async delete(path, fileName) {
    const ref = firebase.storage().ref(path);
    const childRef = ref.child(fileName);
    childRef.delete();
  }

  async upload(_path, _file) {
    try {
      const file = await firebase.storage().ref().child(_path).put(_file);
      const fileUrl = await file.ref.getDownloadURL();

      return fileUrl.toString();
    } catch (error) {
      return "error";
    }
  }
}

export default new FirebaseService();
