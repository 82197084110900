import { CircularProgress, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import Input from "../../components/Input";
import Vista from "../../components/Vista";
import { productDetails } from "../../utils/styles";
import { NavLink } from "react-router-dom";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import Servo from "../../services/servo";
import { useHistory } from "react-router-dom";
import { useMessages } from "../../hooks/useMessages";
import { LoadingButton } from "@mui/lab";
import AutocompleteDeluxe from "../../components/AutocompleteDelux";
import Multifile from "../../components/Multifile";
import { EstilosButton, OtroTallerStyles, buttonStyleToT, dropzoneStyle } from "../../utils/styles";

let vs = productDetails();
let VS2 = EstilosButton();
let vs3 = OtroTallerStyles();
const Detail = ({ match }) => {
  const history = useHistory();
  // const formDefault = {
  //   idCarro: null,
  // };

  //AREA DE DECLARACIONES
  let EstilosButton = VS2();
  let OtroTallerStyles = vs3();
  const { setMessages } = useMessages();
  const permisoCheck = "checklistsCampamento";
  const [permiso] = useLocalStorage("permiso", null);
  const [formDetails, setformDetails] = useState({});
  const id = match?.params?.id;
  const editado = !!id;
  const [loadingB, setLoadingB] = useState(false);
  const [loading, setLoading] = useState(false);
  const [empresa, setEmpresa] = useState(null);
  const [sucursal, setSucursal] = useState(null);
  const [files, setFiles] = useState([]);
  const [carro, setCarro] = useState(null);

  //SE EXTRAEN LOS ARCHIVOS DE LA BASE DE DATOS
  const getDatos = async () => {
    setLoading(true);
    let res = await Servo.get(permisoCheck + "?idCarro=" + id);
    if (res?.cuerpo?.resultado?.length > 0) {
      setformDetails((prevFormDetails) => ({
        ...prevFormDetails,
        ...res?.cuerpo?.resultado[0],
      }));
      setFiles(res?.cuerpo?.resultado[0]?.archivos);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (editado) {
      getDatos();
    }
  }, [empresa, sucursal]);

  //SE GUARDAN LOS ARCHIVOS
  const guardar = async () => {
    setLoadingB(true);
    let body = {
      ...formDetails,
      archivos: files,
      carro: carro
    };

    if (editado) {
      body = { ...body };
    }
    let res = await Servo.post(
      "checklists-campamento/guardar",
      body,
      permisoCheck,
      true,
      history
    );
    if (res?.cuerpo) {
      setMessages(res?.cuerpo);
    }
    setLoadingB(false);
  };

  let productDetails = vs();
  return (
    <Vista>
      <div className={productDetails.screenSize}>
        <div className={productDetails.top}>
          {(permiso[permisoCheck] & 1) === 1 ? (
            <NavLink exact to={"/" + permisoCheck}>
              « Volver
            </NavLink>
          ) : (
            <p>« Volver</p>
          )}
        </div>
        <div className={productDetails.top}>
          <div className={productDetails.title}>
            {editado ? <p>Editar Checklist</p> : <p>Nuevo Checklist</p>}
          </div>
        </div>
        <div className={productDetails.formArea}>
          <Grid container direction="row" columns={{ xs: 12, sm: 12, md: 12 }}>
            {!loading ? (
              <>
                <Grid item
                  xs={12}
                  sm={6}
                  md={4}
                  className={productDetails.inputArea}>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  className={productDetails.inputArea}>
                  <AutocompleteDeluxe
                    labelToShow="Unidad"
                    labelProp="economico"
                    nombre="carros"
                    value={carro}
                    setDato={setCarro}
                  />
                </Grid>
                <Grid item
                  xs={12}
                  sm={6}
                  md={4}
                  className={productDetails.inputArea}>
                </Grid>

              </>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{
                  "@media (min-width: 600px)": {
                    margin: "100px",
                  },
                }}
                className={productDetails.inputArea}>
                <CircularProgress />
              </Grid>
            )}
          </Grid>
        </div>

        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", paddingTop: "10px" }}>

          <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <Multifile loading={loading} setLoading={setLoading} files={files} setFiles={setFiles} firebaseFolder={"PDFs_Unidad"} />
          </div>
        </div>

        <div className={productDetails.buttonArea}>
          {(permiso[permisoCheck] & 2) === 2 ? (
            <LoadingButton
              loading={loadingB}
              onClick={guardar}
              sx={{
                width: "170px",
                height: "50px",
                backgroundColor: "#157CC1",
                fontFamily: "poppins",
                borderRadius: "10px",
                border: "none",
                fontWeight: "500",
                fontSize: "14px",
                letterSpacing: "0.07em",
                color: "#FFFFFF",
                marginLeft: "2px",
                "@media (min-width: 600px)": {
                  marginBottom: "6px",
                },
                "&:hover": {
                  backgroundColor: "#1565c0",
                  cursor: "pointer",
                },
              }}>
              GUARDAR
            </LoadingButton>
          ) : (
            <LoadingButton
              onClick={guardar}
              disabled
              sx={{
                width: "170px",
                height: "50px",
                backgroundColor: "#157CC1",
                fontFamily: "poppins",
                borderRadius: "10px",
                border: "none",
                fontWeight: "500",
                fontSize: "14px",
                letterSpacing: "0.07em",
                color: "#FFFFFF",
                marginLeft: "2px",
                "@media (min-width: 600px)": {
                  marginBottom: "6px",
                },
                "&:hover": {
                  backgroundColor: "#1565c0",
                  cursor: "pointer",
                },
              }}>
              GUARDAR
            </LoadingButton>
          )}
        </div>
      </div>
    </Vista>
  );
};

export default Detail;
