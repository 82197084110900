import React from "react";
import { Switch } from "@mui/material";
import { ChecklistStyles } from "../utils/styles";

let vs = ChecklistStyles();
const SwitchYN = ({ onChange, value }) => {
  let ChecklistStyles = vs();

  return (
    <div className={ChecklistStyles.switchContainer}>
      <span className={ChecklistStyles.switchOption1}>No</span>
      <Switch
        checked={value}
        onChange={onChange}
        name="checked"
        color="primary"
        sx={
          value
            ? {
                zIndex: "6",
                "&:hover .MuiSwitch-switchBase": {
                  color: "#C11515",
                  backgroundColor: "transparent",
                },
                "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                  backgroundColor: "transparent",
                },
                "& .MuiSwitch-switchBase.Mui-checked": {
                  "& .MuiSwitch-thumb": {
                    backgroundColor: "#C11515",
                  },
                },
                "& .MuiSwitch-switchBase.Mui": {
                  "& .MuiSwitch-thumb": {
                    backgroundColor: "#157CC1",
                  },
                },
                "&:hover .MuiSwitch-track": {
                  backgroundColor: "transparent",
                },
              }
            : {
                zIndex: "6",
                "&:hover .MuiSwitch-switchBase": {
                  color: "#157CC1",
                },
                "& .MuiSwitch-switchBase": {
                  color: "#157CC1",
                },
                "& .MuiSwitch-track": { backgroundColor: "transparent" },
                "&:hover .MuiSwitch-track": {
                  backgroundColor: "transparent",
                },
                "& .MuiSwitch-switchBase.Mui": {
                  "& .MuiSwitch-thumb": {
                    backgroundColor: "#C11515",
                  },
                },
              }
        }
      />
      <span className={ChecklistStyles.switchOption2}>Si</span>
    </div>
  );
};

export default SwitchYN;
