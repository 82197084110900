import { Grid } from "@mui/material";
import { ChecklistStyles } from "../../utils/styles";
import CheckComponent from "../../components/checkComponent";

let vs = ChecklistStyles();
const Check = ({
  lucesDelanteras,
  setLucesDelanteras,
  liquidoVehiculo,
  setLiquidoVehiculo,
  lucesTraseras,
  setLucesTraseras,
  carroceria,
  setCarroceria,
  equipoSeguridad,
  setEquipoSeguridad,
  frenos,
  setFrenos,
  llantas,
  setLlantas,
  bateria,
  setBateria,
}) => {
  let ChecklistStyles = vs();


  return (
    <>
      <div className={ChecklistStyles.componentContainer}>
        <div className={ChecklistStyles.internContainer}>
          <Grid
            container
            direction="row"
            sx={{ display: "flex", justifyContent: "center" }}
            columns={{ xs: 12, sm: 12, md: 12 }}>
            <Grid
              item
              xs={12}
              sm={6}
              md={5}
              className={ChecklistStyles.tableArea}>
              <CheckComponent
                Titulo="Luces Delanteras"
                setValue={setLucesDelanteras}
                value={lucesDelanteras}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={5}
              className={ChecklistStyles.tableArea}>
              <CheckComponent
                Titulo="Liquido del vehículo"
                setValue={setLiquidoVehiculo}
                value={liquidoVehiculo}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={5}
              className={ChecklistStyles.tableArea}>
              <CheckComponent
                Titulo="Luces traseras"
                setValue={setLucesTraseras}
                value={lucesTraseras}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={5}
              className={ChecklistStyles.tableArea}>
              <CheckComponent
                Titulo="Carrocería"
                setValue={setCarroceria}
                value={carroceria}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={5}
              className={ChecklistStyles.tableArea}>
              <CheckComponent
                Titulo="Equipo de seguridad"
                setValue={setEquipoSeguridad}
                value={equipoSeguridad}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={5}
              className={ChecklistStyles.tableArea}>
              <CheckComponent
                Titulo="Frenos"
                setValue={setFrenos}
                value={frenos}
                optionsModify="MM 7-12"
                typeOption="input"
                typeFormat="frenos"
                colors={true}
                rangeOk="7mm - 12mm"
                rangeWarning="4mm - 6mm"
                rangeDanger="0mm - 3mm"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={5}
              className={ChecklistStyles.tableArea}>
              <CheckComponent
                Titulo="Llantas"
                setValue={setLlantas}
                value={llantas}
                optionsModify="MM 5-10"
                typeOption="input"
                typeFormat="llantas"
                colors={true}
                rangeOk="5mm - 10mm"
                rangeWarning="3mm - 4mm"
                rangeDanger="0mm - 2mm"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={5}
              className={ChecklistStyles.tableArea}>
              <CheckComponent
                Titulo="Bateria"
                setValue={setBateria}
                value={bateria}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};
export default Check;
