import React, { useState } from "react";
import { Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Input from "../../components/Input";
import AutocompleteDeluxe from "../../components/AutocompleteDelux";
import { EstilosButton, OrdenServicioStyles } from "../../utils/styles";
import { useAlert } from "../../hooks/useAlert";
import Vista from "../../components/Vista";
import { BiPlus } from "react-icons/bi";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import { DeleteOutlined } from "@mui/icons-material";

let VS = OrdenServicioStyles();
let VS2 = EstilosButton();
const ManosObra = ({
  manosObra,
  setManosObra,
  guardarManosObra,
  mecanicosSeleccionados,
  setMecanicosSeleccionados,
}) => {
  let OrdenServicioStyles = VS();
  let EstilosButton = VS2();
  const { showAlert } = useAlert();
  const [manoObra, setManoObra] = useState(null);

  const addServices = async () => {
    if (manoObra != null) {
      let copyManosObra = [...manosObra];
      let validacion = manosObra.some((mano) => {
        return mano.idManoObra === manoObra.idManoObra;
      });
      if (validacion) {
        showAlert({
          message: "Servicio repetido",
          severity: "warning",
        });
        return;
      }
      copyManosObra.push({ ...manoObra });
      setManosObra([...copyManosObra]);
    } else {
      showAlert({
        message: "Campo de Mano de Obra Vacío",
        severity: "warning",
      });
      return;
    }
  };

  const deleteServices = (idManoObra) => {
    let copyServices = [...manosObra];
    let index = copyServices.findIndex((services) => {
      return services.idManoObra === idManoObra;
    });
    copyServices.splice(index, 1);
    setManosObra([...copyServices]);

    showAlert("Servicio eliminado", "success");
  };

  return (
    <Vista>
      <div className={OrdenServicioStyles.container}>
        <div className={OrdenServicioStyles.formArea}>
          <div className={OrdenServicioStyles.formDataContainer}>
            <Grid
              container
              direction="row"
              sx={{ width: "100%", height: "100%" }}
              columns={{ xs: 12, sm: 12, md: 12 }}>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "92px",
                  padding: "0 10px 0 10px",
                }}>
                <AutocompleteDeluxe
                  required
                  labelToShow="Manos de Obra"
                  labelProp="codigo"
                  nombre="manos-obra"
                  value={manoObra}
                  setDato={setManoObra}
                  background={"#FFFFFF"}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  "@media (max-width: 600px)": {
                    alignItems: "center",
                  },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  height: "92px",
                  padding: "0 10px 0 10px",
                }}>
                <Button
                  sx={{
                    width: "100%",
                    height: "41px",
                    backgroundColor: "#157CC1",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    textTransform: "none",
                    marginBottom: "7px",
                  }}
                  onClick={addServices}
                  variant="contained">
                  <BiPlus className={EstilosButton.icon} />
                  <p className={EstilosButton.font}>Agregar Producto</p>
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={OrdenServicioStyles.formAreaTable}>
          <Paper sx={{ "@media (max-width: 600px)": { width: "98%", minHeight: "50%" }, width: "70%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 320 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow className={OrdenServicioStyles?.tableHead}>
                    <TableCell className="headColor"
                      sx={{ fontFamily: "poppins" }}>#</TableCell>
                    <TableCell className="headColor"
                      sx={{ fontFamily: "poppins" }}>Codigo</TableCell>
                    <TableCell className="headColor"
                      sx={{ fontFamily: "poppins" }}>Descripcion</TableCell>
                    <TableCell className="headColor"
                      sx={{ fontFamily: "poppins" }}>Precio</TableCell>
                    <TableCell className="headColor"
                      sx={{ fontFamily: "poppins" }}>Mecanico a Cargo</TableCell>
                    <TableCell className="headColor"
                      sx={{ fontFamily: "poppins" }}>Eliminar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {manosObra && manosObra.length > 0 && manosObra.map((services, index) => (
                    <TableRow className={OrdenServicioStyles?.tableRow} key={index}>
                      <TableCell className="rowColor"
                        sx={{ fontFamily: "poppins" }}>{index + 1}
                      </TableCell>
                      <TableCell className="rowColor"
                        sx={{ fontFamily: "poppins", lineHeight: "13px" }}>{services?.manosObra?.codigo || services?.codigo}
                      </TableCell>
                      <TableCell className="rowColor"
                        sx={{ fontFamily: "poppins", lineHeight: "13px" }}>{services?.manosObra?.descripcion || services?.descripcion}
                      </TableCell>
                      <TableCell className="rowColor"
                        sx={{ fontFamily: "poppins", lineHeight: "13px" }}>{services?.precio}
                      </TableCell>
                      <TableCell className="rowColor"
                        sx={{ fontFamily: "poppins", lineHeight: "13px" }}>
                        <Input
                          typeInput={"formSelect"}
                          valor={mecanicosSeleccionados[services.idManoObra] || ''}
                          onChange={(e) => {
                            const selectedMecanico = e.target.value;
                            setMecanicosSeleccionados(prevState => ({
                              ...prevState,
                              [services.idManoObra]: selectedMecanico,
                            }));
                          }}
                          data={[
                            "BARAJAS OJEDA SERGIO RAUL",
                            "LUGO ISLAS MARCOS ALEXIS",
                            "CRUZ CRUZ FRANCISCO ALBERTO",
                            "CORDOVA COHEN LUIS RAMON",
                            "HERNANDEZ ALCALA FERNANDO",
                            "CARLOS HASSIEL PAYAN MARTINEZ",
                            "TERAN VALENZUELA LUIS GUSTAVO",
                            "PAYAN QUINTERO JESUS MIGUEL",
                            "GURROLA PERAL JUAN FERNANDO",
                            "MEDRANO BELTRAN MARCO ANTONIO"
                          ]}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => deleteServices(services.idManoObra)}>
                          <DeleteOutlined />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))

                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>

        <div className={OrdenServicioStyles?.buttonAreaContainer}>
          <div className={OrdenServicioStyles?.buttonContainer}
            style={{ display: "flex", justifyContent: "flex-end", paddingTop: "10px" }}>
            <LoadingButton
              // loading={loadingB}
              // onClick={guardar}
              sx={{
                width: "170px",
                height: "50px",
                backgroundColor: "#157CC1",
                fontFamily: "poppins",
                borderRadius: "10px",
                border: "none",
                fontWeight: "500",
                fontSize: "14px",
                letterSpacing: "0.07em",
                color: "#FFFFFF",
                marginLeft: "2px",
                "@media (min-width: 600px)": {
                  marginBottom: "6px",
                },
                "&:hover": {
                  backgroundColor: "#1565c0",
                  cursor: "pointer",
                },
              }}
              className={OrdenServicioStyles?.buttonSave}
              onClick={async () => {
                guardarManosObra(manosObra, mecanicosSeleccionados);
              }}>
              GUARDAR
            </LoadingButton>
          </div>
        </div>

      </div>
    </Vista>
  );
}

export default ManosObra;