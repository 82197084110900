import { ChecklistStyles } from "../../utils/styles";

let vs = ChecklistStyles();
const Selector = ({ setScreen = () => { }, screen, toChange }) => {
  let ChecklistStyles = vs();

  const change = (ventana) => {
    let validaciones = toChange();
    switch (ventana) {
      case "DatosGenerales":
        setScreen(ventana);
        break;
      case "Checklist":
        if (validaciones?.dg) {
          setScreen(ventana);
        }
        break;
      case "Gasolina":
        if (validaciones?.dg && validaciones?.check) {
          setScreen(ventana);
        }
        break;
      case "checklistArchivos":
        if (validaciones?.dg && validaciones?.check && validaciones?.gas) {
          setScreen(ventana);
        }
        break;
      case "Firma":
        if (validaciones?.dg && validaciones?.check && validaciones?.gas && validaciones?.evidencia) {
          setScreen(ventana);
        }
        break;
    }
  };

  return (
    <div className={ChecklistStyles.selectionContainer}>
      <div className={ChecklistStyles.buttonArea}>
        <button
          onClick={(e) => change("DatosGenerales")}
          className={
            screen === "DatosGenerales"
              ? ChecklistStyles.buttonFocus
              : ChecklistStyles.button
          }>
          Datos Generales
        </button>
      </div>
      <div className={ChecklistStyles.buttonArea}>
        <button
          onClick={(e) => change("Checklist")}
          className={
            screen === "Checklist"
              ? ChecklistStyles.buttonFocus
              : ChecklistStyles.button
          }>
          Checklist
        </button>
      </div>
      <div className={ChecklistStyles.buttonArea}>
        <button
          onClick={(e) => change("Gasolina")}
          className={
            screen === "Gasolina"
              ? ChecklistStyles.buttonFocus
              : ChecklistStyles.button
          }>
          Gasolina / km
        </button>
      </div>
      <div className={ChecklistStyles.buttonArea}>
        <button
          onClick={(e) => change("checklistArchivos")}
          className={
            screen === "checklistArchivos"
              ? ChecklistStyles.buttonFocus
              : ChecklistStyles.button
          }>
          Evidencias
        </button>
      </div>
      <div className={ChecklistStyles.buttonArea}>
        <button
          onClick={(e) => change("Firma")}
          className={
            screen === "Firma"
              ? ChecklistStyles.buttonFocus
              : ChecklistStyles.button
          }>
          Firma
        </button>
      </div>
    </div>
  );
};
export default Selector;
