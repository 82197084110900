import React from "react";
import { AvatarStyles } from "../utils/styles";

let AS = AvatarStyles();
const Avatar = ({ img, alt, size, ...props }) => {
  const AvatarStyles = AS();
  return (
    <img
      className={AvatarStyles.img}
      style={{ width: size, height: size }}
      src={img}
      alt={alt}
    />
  );
};

export default Avatar;
