import { useEffect, useState } from "react";
import { DigitalSignature } from "../../components/DigitalSignature";
import Input from "../../components/Input";
import { ChecklistStyles } from "../../utils/styles";
import { productDetails } from "../../utils/styles";
import { LoadingButton } from "@mui/lab";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import AutocompleteDeluxe from "../../components/AutocompleteDelux";

let vs = ChecklistStyles();
let vs2 = productDetails();
const Firma = ({
  guardar,
  permisoCheck,
  canvasFirma,
  firma,
  setFirma,
  loadingButton,
  empleadoEntrega,
  setEmpleadoEntrega,
  empleadoRecibe,
  setEmpleadoRecibe,
  rentaPrestamo,
  setRentaPrestamo,
  personaExterna,
  setPersonaExterna,
}) => {
  let ChecklistStyles = vs();
  let productDetails = vs2();
  const [permiso] = useLocalStorage("permiso", null);
  const [firmar, setFirmar] = useState(false);
  const [tempImg, setTempImg] = useState(null);
  const [externo, setExterno] = useState(false);

  useEffect(() => {
    if (firma) {
      setTempImg(firma.toDataURL());
    }
  }, [firma]);

  const handleExternoChange = () => {
    setExterno(!externo);
    // Si externo es verdadero, puedes realizar otras acciones aquí.
  }

  return (
    <div
      style={{ display: "flex", flexWrap: "wrap" }}
      className={ChecklistStyles.componentContainer}>

      <div className={ChecklistStyles.dateNameContainer}>
        <div className={ChecklistStyles.sizeDataName}>
          <AutocompleteDeluxe
            labelToShow="Empleado que entrega"
            labelProp="nombre"
            nombre="empleados"
            value={empleadoEntrega?.nombre}
            setDato={setEmpleadoEntrega}
          />
        </div>
      </div>
      <div className={ChecklistStyles.dateNameContainer}>
        <div className={ChecklistStyles.sizeDataName}>
          <div style={{ width: "60%" }}>
            <input
              type="checkbox"
              id="externoCheckbox"
              checked={externo}
              onChange={handleExternoChange}
            />
            <label htmlFor="externoCheckbox">Persona Externa</label>
          </div>
          {externo ? (
            <Input
              typeInput={"form"}
              valor={personaExterna}
              setValor={setPersonaExterna}
              placeholder="Nombre del empleado que recibe"
              nombre="Nombre del empleado que recibe"
              required="false"
              onChange={(e) => {
                setPersonaExterna(e.target.value);
              }} />
          ) : (
            <AutocompleteDeluxe
              labelToShow="Empleado que recibe"
              labelProp="nombre"
              nombre="empleados"
              value={empleadoRecibe?.nombre}
              setDato={setEmpleadoRecibe}
            />
          )}
        </div>
      </div>
      <div className={ChecklistStyles.dateNameContainer}>
        <div className={ChecklistStyles.sizeDataName}>
          <Input
            typeInput={"formSelect"}
            valor={rentaPrestamo}
            placeholder="Renta o Prestamo"
            nombre="Renta o Prestamo"
            onChange={(e) => {
              setRentaPrestamo(e.target.value);
            }}
            data={[
              "RENTA",
              "PRESTAMO",
            ]}
          />
        </div>
      </div>
      <div className={ChecklistStyles.firmaContainer}>
        <>
          {firmar ? (
            <DigitalSignature
              ref={canvasFirma}
              firma={firma}
              setFirma={setFirma}
              setFirmar={setFirmar}
            />
          ) : (
            <img
              onClick={(e) => {
                setFirmar(true);
              }}
              style={{ maxWidth: "100%", height: "100%" }}
              src={
                tempImg
                  ? tempImg
                  : "https://firebasestorage.googleapis.com/v0/b/react-clinica.appspot.com/o/DoctoresDetalles%2Ffirma_default.png?alt=media&token=c657d017-f580-487d-b518-f79db11a76db"
              }
              alt={"firma del paciente"}
            />
          )}
        </>
      </div>
      <div className={productDetails.buttonAreaEspecial}>
        {(permiso[permisoCheck] & 2) === 2 ? (
          <LoadingButton
            loading={loadingButton}
            onClick={guardar}
            sx={{
              width: "170px",
              height: "50px",
              backgroundColor: "#157CC1",
              fontFamily: "poppins",
              borderRadius: "10px",
              border: "none",
              fontWeight: "500",
              fontSize: "14px",
              letterSpacing: "0.07em",
              color: "#FFFFFF",
              marginLeft: "2px",
              "@media (min-width: 600px)": {
                marginBottom: "6px",
              },
              "&:hover": {
                backgroundColor: "#1565c0",
                cursor: "pointer",
              },
            }}>
            GUARDAR
          </LoadingButton>
        ) : (
          <LoadingButton
            onClick={guardar}
            disabled
            sx={{
              width: "170px",
              height: "50px",
              backgroundColor: "#157CC1",
              fontFamily: "poppins",
              borderRadius: "10px",
              border: "none",
              fontWeight: "500",
              fontSize: "14px",
              letterSpacing: "0.07em",
              color: "#FFFFFF",
              marginLeft: "2px",
              "@media (min-width: 600px)": {
                marginBottom: "6px",
              },
              "&:hover": {
                backgroundColor: "#1565c0",
                cursor: "pointer",
              },
            }}>
            GUARDAR
          </LoadingButton>
        )}
      </div>
    </div>
  );
};
export default Firma;
