import moment from "moment";

class dateComponent {
   getFirstDayOfYear() {
    let firstDay = String(
      moment(new Date()).startOf("year").format("YYYY-MM-DD")
    );
    return firstDay;
  }

   getActualDay() {
    let actualDay = String(moment(new Date()).format("YYYY-MM-DD"));
    return actualDay;
  }
}

export default new dateComponent();
